/* eslint-disable @typescript-eslint/camelcase */
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import { CargoPresenter } from "../presenters/CargoPresenter";
import {
  CargoCommodityList,
  CargoData,
  CargoDetailStt,
  CargoEntities,
  CargoPieceDetail,
  CargoSttDetail,
  CargoSttList,
  CargoSttValidaton,
  CargoProductEntities,
  SearchCargoOptions,
  AirportEntities,
  AirportData,
  DimensionData,
  ListLastReadCargo,
  AirportDetail,
  CargoInputData,
  DestinationCityData,
  OriginCityData
} from "@/domain/entities/Cargo";
import {
  ReadyToCargo,
  ReadyToCargoListEntities
} from "@/domain/entities/ReadyToCargo";
import { Pagination } from "@/domain/entities/Pagination";
import {
  EditCargoApiRequest,
  EditCargoBagOrStt,
  AddBagOrSttNumberRequest,
  BagOrSttNumber,
  AddBagOrStt,
  AddCargoRequest,
  FlightPlanForECargo,
  CargoListRequest,
  UpdateRetryCargoRequest,
  DimensionPieces
} from "@/data/payload/api/CargoApiRequest";
import { ResponseBagSttDetail } from "@/domain/entities/ResponsePayload";
import { MainAppController } from "./MainAppController";
import convertDecimalWithComma from "@/app/infrastructures/misc/common-library/ConvertDecimalWithComma";
import formatDecimal from "@/app/infrastructures/misc/common-library/FormatDecimal";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { AwbDetailData, FlightDetail } from "@/domain/entities/NGen";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { NGenController } from "./NGenController";
import { gtmCargoData } from "../views/cargo/modules/gtm";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { app } from "@/main";
import { RegionBaggingGrouping } from "@/domain/entities/Bagging";
import { BaggingController } from "./BaggingController";
import firstCapitalize from "@/app/infrastructures/misc/common-library/FirstCapitalize";
import { ModalMessageEntities, OptionsClass } from "@/domain/entities/MainApp";
import router from "../router";
import { ReadyToCargoController } from "./ReadyToCargoController";
import groupBy from "lodash/groupBy";
import { storeDatatoLocalStorage } from "@/app/infrastructures/misc/LocalStorage";
import { LocationController } from "./LocationController";
import uniqBy from "lodash/uniqBy";
import capitalize from "lodash/capitalize";
import { GTMBookingCargoEvent } from "@/app/infrastructures/misc/gtm-event/custom-events/booking-cargo-event";
import { getHubData } from "@/app/infrastructures/misc/Cookies";
import { cargoTypeData } from "../views/cargo/modules/cargo-module";
import { flags } from "@/feature-flags";
import { FlagsPermissionCargo } from "@/feature-flags/flags-permission-cargo";
import { formatDate } from "@/app/infrastructures/misc/Utils";

export interface CargoState {
  isLoading: boolean;
  isLoadingCargoProductList: boolean;
  cargoProductList: CargoProductEntities;
  cargoProductListError: boolean;
  cargoProductLisErrorCause: string;
  isLoadingAppend: boolean;
  modalSuccessAppend: boolean;
  isErrorAppend: boolean;
  errorCauseAppend: string;
  bagSttDetail: ResponseBagSttDetail;
  isCargoAvailable: boolean;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "cargo-controller"
})
class CargoStore extends VuexModule implements CargoState {
  public isLoading = false;
  public isLoadingCargoProductList = false;
  public cargoProductList = new CargoProductEntities([]);
  public cargoProductListError = false;
  public cargoProductLisErrorCause = "";
  public isLoadingPrint = false;
  public isErrorPrint = false;
  public advanceFilterData = {
    type: { name: "", value: "" },
    cityOriginCode: "",
    cityDestinationCode: "",
    airport: ""
  };
  public isError = false;
  public errorCause = "";
  public isOpenModalTopup = false;
  public search = "";
  public pagination = {
    page: 1,
    limit: 10
  };
  public cargoData = new CargoEntities(new Pagination(1, 20), []);
  public retryCargo = new CargoEntities(new Pagination(1, 20), []);
  public airportData = new AirportEntities(new Pagination(1, 10), []);
  public airportDetail = new AirportDetail("", "");
  public airportDetailOrigin = new AirportDetail("", "");
  public airportDetailDestination = new AirportDetail("", "");
  public airportDataOrigin = new AirportEntities(new Pagination(1, 10), []);
  public airportDataDestination = new AirportEntities(
    new Pagination(1, 10),
    []
  );
  public isLoadingAirport = false;
  public periodeStart: any = null;
  public periodeEnd: any = null;
  public isFailed = false;
  public isOpenSuccess = false;
  public sttFailed: any[] = [];
  public cargoIdToGeneratePdf = "";
  public cargoIdToCancelOrDelete = 0;
  public cargoNoForModal = "";
  public cargoDetailData = new CargoData();
  public timestampBooking = 0;
  public cargoInputForm: CargoInputData = JSON.parse(
    localStorage.getItem("cargo-input-data") || "{}"
  );
  public cargoType = JSON.parse(localStorage.getItem("cargo-type") || "{}");
  public showSelectAirportFromLocalStorage =
    (localStorage.getItem("show-select-airport") == "true" ? true : false) ||
    false;

  public isChange = localStorage.getItem("is-change-threshold-time") || false;
  public CargoInputData = new CargoInputData({
    id: this.cargoInputForm.id || 0,
    dimension: this.cargoInputForm.dimension || new DimensionData(),
    totalActualCargoGrossWeight:
      this.cargoInputForm.totalActualCargoGrossWeight || 0,
    totalActualCargoVolumeWeight:
      this.cargoInputForm.totalActualCargoVolumeWeight || 0,
    totalActualCargoColi: this.cargoInputForm.totalActualCargoColi || 0,
    destinationCityData: this.cargoInputForm.destinationCityData || "",
    originCityData: this.cargoInputForm.originCityData || {},
    nog: this.cargoInputForm.nog || "",
    cargoComodity: this.cargoInputForm.cargoComodity || "",
    cargoProduct: this.cargoInputForm.cargoProduct || "",
    vehicleNumber: this.cargoInputForm.vehicleNumber || "",
    cargoNumber: this.cargoInputForm.cargoNumber || "",
    airportOriginCode: this.cargoInputForm.airportOriginCode || "",
    airportDestinationCode: this.cargoInputForm.airportDestinationCode || "",
    estTotalCargoVolumeWeight:
      this.cargoInputForm.estTotalCargoVolumeWeight || 0,
    estTotalCargoGrossWeight: this.cargoInputForm.estTotalCargoGrossWeight || 0,
    estDepartureDate: this.cargoInputForm.estDepartureDate || new Date(),
    estArrivalDate: this.cargoInputForm.estArrivalDate || new Date(),
    airportOrigin:
      this.cargoInputForm.airportOrigin ||
      new AirportData("", "", "", "", "", ""),
    airportDestination:
      this.cargoInputForm.airportDestination ||
      new AirportData("", "", "", "", "", ""),
    thresholdTime: this.isChange
      ? this.cargoInputForm.thresholdTime
      : flags.cargo_config_flight_treshold_default_value.getValue()
  });
  public detailFlightsData: FlightDetail[] = JSON.parse(
    localStorage.getItem("rtc-flights-data") || "[]"
  );
  public flowBooking: string = JSON.parse(
    localStorage.getItem("flow-booking") || "{}"
  );

  // create
  public showCargoOptions = false;
  public cargoOptions: SearchCargoOptions = new SearchCargoOptions([]);
  form = {
    cargoType: this.cargoType.type || "",
    cargoTypeStatus: this.cargoType.status || "",
    destinationCityName: this.CargoInputData.destinationCityData.name,
    destinationCityCode: this.CargoInputData.destinationCityData.code,
    estDepartureDate: this.CargoInputData.estDepartureDate,
    estArrivalDate: this.CargoInputData.estArrivalDate,
    nog: this.CargoInputData.nog,
    estTotalCargoGrossWeight: this.CargoInputData.estTotalCargoGrossWeight.toString(),
    estTotalCargoVolumeWeight: this.CargoInputData.estTotalCargoVolumeWeight.toString(),
    cargoCommodityName: this.CargoInputData.cargoComodity.name ?? "",
    cargoCommodityCode: this.CargoInputData.cargoComodity.code ?? "",
    cargoProduct: "Express",
    cargoNumber: this.CargoInputData.cargoNumber,
    vehicleNumber: this.CargoInputData.vehicleNumber,
    actualTotalCargoGrossWeight: this.CargoInputData.totalActualCargoGrossWeight.toString(),
    actualTotalCargoVolumeWeight: this.CargoInputData.totalActualCargoVolumeWeight.toString(),
    actualTotalSTTPieces: this.CargoInputData.totalActualCargoColi,
    sttList: [] as CargoDetailStt[],
    totalCargoVolumeWeight: 400,
    airportOrigin: {
      airportCode:
        this.detailFlightsData && this.detailFlightsData[0]?.Route != ""
          ? this.detailFlightsData[0]?.Route.split("-")[0]
          : this.CargoInputData.airportOriginCode != ""
          ? this.CargoInputData.airportOriginCode
          : "",
      airportName: this.CargoInputData.airportOrigin.airportName,
      cityCode: this.CargoInputData.airportOrigin.cityCode,
      cityName: this.CargoInputData.airportOrigin.cityName,
      name: this.CargoInputData.airportOrigin.name,
      value:
        this.detailFlightsData && this.detailFlightsData[0]?.Route != ""
          ? this.detailFlightsData[0]?.Route.split("-")[0]
          : this.CargoInputData.airportOriginCode != ""
          ? this.CargoInputData.airportOriginCode
          : ""
    },
    airportDestination: {
      airportCode:
        this.detailFlightsData &&
        this.detailFlightsData[this.detailFlightsData.length - 1]?.Route != ""
          ? this.detailFlightsData[
              this.detailFlightsData.length - 1
            ]?.Route.split("-")[1]
          : this.CargoInputData.airportDestinationCode != ""
          ? this.CargoInputData.airportDestinationCode
          : "",
      airportName: this.CargoInputData.airportDestination.airportName,
      cityCode: this.CargoInputData.airportDestination.cityCode,
      cityName: this.CargoInputData.airportDestination.cityName,
      name: this.CargoInputData.airportDestination.name,
      value:
        this.detailFlightsData &&
        this.detailFlightsData[this.detailFlightsData.length - 1]?.Route != ""
          ? this.detailFlightsData[
              this.detailFlightsData.length - 1
            ]?.Route.split("-")[1]
          : this.CargoInputData.airportDestinationCode != ""
          ? this.CargoInputData.airportDestinationCode
          : ""
    },
    flightsData: this.detailFlightsData,
    airports: [] as any[],
    transit: "0",
    isChangeAirport: false,
    estDimension: {
      length: 0,
      width: 0,
      height: 0
    },
    actualDimension: {
      length: this.CargoInputData.dimension.length,
      width: this.CargoInputData.dimension.width,
      height: this.CargoInputData.dimension.height
    },
    ngenCreatedAt: "",
    isRtc: this.flowBooking == "rtc-process" ? true : false,
    thresholdTime: this.CargoInputData.thresholdTime,
    shcName: "" as any,
    shcCode: "" as any
  };
  public sttResultFinal: CargoDetailStt[] = JSON.parse(
    localStorage.getItem("cargo") || "[]"
  );
  public bagResult: {
    baggingNumber: string;
    bagGrossWeight: number;
    stt: CargoDetailStt[];
  }[] = JSON.parse(localStorage.getItem("cargo-bag") || "[]");
  public sourceSttFromLocalStorage: CargoDetailStt[] = JSON.parse(
    localStorage.getItem("cargo") || "[]"
  );
  regionBaggingGrouping = new RegionBaggingGrouping({
    baggingRegionDestinationId: "",
    baggingRegionDestinationName: ""
  });
  public destinationCity: any = this.CargoInputData.destinationCityData;
  public showNotesDestination = false;
  public loadingRegion = false;
  public showNotificationWeight = false;
  public isChangePlaneSchedule = false;
  public isPlaneBooking = false;
  public isShowPlaneSchedule = false;

  public cargoCommodityList = new CargoCommodityList(new Pagination(1, 10), []);
  public isLoadingCargoCommodity = false;
  public isLoadingAppend = false;
  public modalSuccessAppend = false;
  public isErrorAppend = false;
  public errorCauseAppend = "";
  public bagSttDetail = new ResponseBagSttDetail(false, "", []);
  public isFullBooked = false;
  public isCargoAvailable = false;
  public isFillForm: boolean =
    localStorage.getItem("fill-form") == "true" || false;
  public isDeleteStt = JSON.parse(
    localStorage.getItem("is-delete-stt") || "false"
  );
  public airportsPopupModel: any[] = [];
  public isShowBookingForm = false;
  public listLastReadCargo: ListLastReadCargo[] = [];
  public progressValue = JSON.parse(
    localStorage.getItem("progress-value") || "0"
  );
  public cargoStatus = "";
  public cancelFromDetail = false;
  public deleteFromDetail = false;
  public isCacheCommodityGroup = false;
  public showModalCargoType: boolean =
    (localStorage.getItem("show-modal-cargo") == "true" ? true : false) ||
    false;
  public isEditReadyToCargo =
    (localStorage.getItem("isEditReadyToCargo") == "true" ? true : false) ||
    false;
  public isPlaneAutoFlow = false;
  public commodityItem: OptionsClass = new OptionsClass({
    name: this.cargoInputForm.cargoComodity?.name,
    code: this.cargoInputForm.cargoComodity?.code
  });
  public commodityOptions: OptionsClass[] = [];
  public lastReadCargoSession: { start: 0; end: 0 } = JSON.parse(
    localStorage.getItem("last-read-cargo") || "{}"
  );
  public calculateTotalGrossWeight = 0;
  public disableCommodityGroup = false;
  public isErrorInputStepThree = {
    dimension: false,
    volumeWeight: false,
    grossWeight: false,
    totalPieces: false
  };
  public isShowPlaneScheduleFromLocalStorage =
    localStorage.getItem("setShowPlaneSchedule") == "true" || false;
  public showPopupDimensionPieces = false;
  public formDetailPieces: {
    totalPieces: number;
    dimensionPieces: DimensionPieces[];
  } = {
    totalPieces: 0,
    dimensionPieces: [
      {
        pieces: 0,
        length: 30,
        width: 20,
        height: 10,
        grossWeight: 1
      }
    ]
  };

  public isHaveBeenSubmitDetailPieces = false;
  public isErrorDiffPiecesActualThanDimensionPieces = false;
  public isChangeCargoType = JSON.parse(
    localStorage.getItem("cargo-is-change-cargo-type") || "false"
  );
  public tabsList = JSON.parse(localStorage.getItem("cargo-tabs-list") || "[]");
  public isShowModalGamification = JSON.parse(
    localStorage.getItem("is-modal-gamification") || "false"
  );
  public isModalGamificationDone = JSON.parse(
    localStorage.getItem("is-modal-gamification-done") || "false"
  );
  public isChangeVolumeActual = JSON.parse(
    localStorage.getItem("is-change-total-actual-volume") || "false"
  );
  public isFromRtc = false;

  public isRebookingFromRTC = JSON.parse(
    localStorage.getItem("is-from-rebooking-rtc") || "false"
  );
  public isTimeoutNgen = false;
  public destinationCityRTC: any = "";
  public clientValue: any = "";
  public readyToCargoList = new ReadyToCargoListEntities({
    pagination: {
      page: 1,
      limit: 20
    },
    data: []
  });
  public activeTabs = "plane";
  @Action
  public async getCargoList(params: CargoListRequest) {
    const requestParams = new CargoListRequest({
      ...params,
      hubId: Number(getHubData()?.hubId || 0)
    });
    this.setLoading(true);
    const presenter = container.resolve(CargoPresenter);
    return presenter
      .getCargoList(requestParams)
      .then((res: any) => {
        this.setCargoList(res);
        this.setError(false);
        this.setErrorCause("");
        return res.data;
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
        return this.cargoData.data;
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public getRetryCargoList(params: CargoListRequest) {
    const requestParams = new CargoListRequest({
      ...params,
      hubId: Number(getHubData()?.hubId || 0)
    });
    this.setLoading(true);
    const presenter = container.resolve(CargoPresenter);
    return presenter
      .getRetryCargoList(requestParams)
      .then((res: any) => {
        this.setRetryCargo(res);
        this.setError(false);
        this.setErrorCause("");
        return res.data;
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
        return this.retryCargo.data;
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public handleGetLastReadCargo(isExpired: boolean, isEmpty: boolean) {
    if (isExpired) {
      this.getLastReadCargo();
      this.setLastReadCargoSession({
        start: new Date().getTime(),
        end: new Date(new Date().getTime() + 5 * 60000).getTime()
      });
    }
    if (isEmpty) {
      this.getLastReadCargo();
      this.setLastReadCargoSession({
        start: new Date().getTime(),
        end: new Date(new Date().getTime() + 5 * 60000).getTime()
      });
    }
  }

  @Action
  public getLastReadCargo() {
    const presenter = container.resolve(CargoPresenter);
    presenter.getListLastCargo().then((res: ListLastReadCargo[]) => {
      const toast = app.config.globalProperties.$toast;
      if (res.length !== 0) {
        const checkStatus = !!res.find((item: any) => {
          return item.status === "failed";
        });
        if (checkStatus) {
          playNotification("failed_cargo");
        } else {
          playNotification("success_cargo");
        }
        res.forEach((item: ListLastReadCargo) => {
          toast.add({
            severity: item.status === "success" ? "success" : "error",
            detail: `<b>No. Kargo ${item.cargoNumber}</b> ${
              item.status === "success"
                ? `Berhasil Booking kargo ke <b>${item.destination}</b>`
                : `Gagal Booking ${
                    item.message?.toLowerCase().includes("sistem")
                      ? `ke ${item.destination} karena <b>terjadi kendala sistem</b>`
                      : `karena <b>${item.message}</b>`
                  }`
            }`,
            life: 10000
          });
        });
      }
    }).catch((err: any) => {
      console.warn(err);
    })
  }

  @Action
  public updateLastReadCargo() {
    const presenter = container.resolve(CargoPresenter);
    presenter
      .updateListLastCargo()
      .then(() => {
        return true;
      })
      .catch(err => {
        return false;
      });
  }

  @Action
  public getDetailCargo(params: { cargoNo: string; isPrint: boolean }) {
    if (params.isPrint) {
      MainAppController.showLoading();
      MainAppController.closeErrorMessage();
    } else {
      this.setLoading(true);
    }

    const presenter = container.resolve(CargoPresenter);
    return presenter
      .getDetailCargo(params.cargoNo)
      .then((res: CargoData) => {
        res["totalActualCargoGrossWeight"] = formatDecimal(
          String(res.totalActualCargoGrossWeight)
        );
        res["totalActualCargoVolumeWeight"] = formatDecimal(
          String(res.totalActualCargoVolumeWeight)
        );
        this.setCargoDetailData(res);
        this.setError(false);
        this.setErrorCause("");
        return res;
      })
      .catch((error: any) => {
        if (params.isPrint) {
          MainAppController.showErrorMessage(
            parsingErrorResponse(error, "Print Gagal !", () =>
              this.getDetailCargo(params)
            )
          );
        } else {
          this.setError(true);
          this.setErrorCause(error.response ? "server" : "internet");
        }

        return new CargoData();
      })
      .finally(() => {
        if (params.isPrint) {
          MainAppController.closeLoading();
        } else {
          this.setLoading(false);
        }
      });
  }

  @Action
  public getDetailRetryCargo(params: { cargoNo: string; isPrint: boolean }) {
    if (params.isPrint) {
      MainAppController.showLoading();
      MainAppController.closeErrorMessage();
    } else {
      this.setLoading(true);
    }

    const presenter = container.resolve(CargoPresenter);
    return presenter
      .getDetailRetryCargo(params.cargoNo)
      .then((res: CargoData) => {
        res["totalActualCargoGrossWeight"] = formatDecimal(
          String(res.totalActualCargoGrossWeight)
        );
        res["totalActualCargoVolumeWeight"] = formatDecimal(
          String(res.totalActualCargoVolumeWeight)
        );
        this.setCargoDetailData(res);
        this.setError(false);
        this.setErrorCause("");
        return res;
      })
      .catch((error: any) => {
        if (params.isPrint) {
          MainAppController.showErrorMessage(
            parsingErrorResponse(error, "Print Gagal !", () =>
              this.getDetailRetryCargo(params)
            )
          );
        } else {
          this.setError(true);
          this.setErrorCause(error.response ? "server" : "internet");
        }

        return new CargoData();
      })
      .finally(() => {
        if (params.isPrint) {
          MainAppController.closeLoading();
        } else {
          this.setLoading(false);
        }
      });
  }

  @Action
  public searchAction(value: string) {
    this.setSearch(value);
    this.setFirstPage();
    this.fetchCargoList({ type: this.cargoStatus ? "retry" : "" });
  }

  @Action
  public fetchCargoList(params = { type: "" }) {
    const accountOrigin =
      AccountController.accountData.account_location.city_code;
    const payload = new CargoListRequest({
      page: this.cargoStatus
        ? this.retryCargo.pagination.page
        : this.cargoData.pagination.page,
      limit: this.cargoStatus
        ? this.retryCargo.pagination.limit
        : this.cargoData.pagination.limit,
      search: encodeURIComponent(this.search),
      cargoType: this.advanceFilterData.type.value,
      originCity: this.advanceFilterData.cityOriginCode
        ? this.advanceFilterData.cityOriginCode
        : accountOrigin,
      destinationCity: this.advanceFilterData.cityDestinationCode,
      startDate: this.periodeStart
        ? new Date(this.periodeStart).toLocaleDateString("fr-CA")
        : "",
      endDate: this.periodeEnd
        ? new Date(this.periodeEnd).toLocaleDateString("fr-CA")
        : "",
      destinationAirport: this.advanceFilterData.airport,
      status: this.cargoStatus ?? ""
    });
    return params.type === "retry"
      ? this.getRetryCargoList(payload)
      : this.getCargoList(payload);
  }

  @Action
  public async fetchLastIdCargoRetryCargo(type: string) {
    const accountOrigin =
      AccountController.accountData.account_location.city_code;
    const payload = new CargoListRequest({
      cargoType: this.advanceFilterData.type.value,
      originCity: this.advanceFilterData.cityOriginCode
        ? this.advanceFilterData.cityOriginCode
        : accountOrigin,
      destinationCity: this.advanceFilterData.cityDestinationCode,
      status: this.cargoStatus ?? "",
      hubId: Number(getHubData()?.hubId || 0)
    });
    try {
      if (type === "retry-cargo") {
        const presenter = container.resolve(CargoPresenter);
        return await presenter.getLastIdRetryCargo(payload);
      } else {
        const presenter = container.resolve(CargoPresenter);
        return await presenter.getLastIdCargo(payload);
      }
    } catch (error) {
      return 0;
    }
  }

  @Action
  public getAirportList(params: {
    airportCode: string;
    cityCode: string;
    page: number;
    limit: number;
    isLocation?: boolean;
    hideDefault?: boolean;
    search?: string;
  }) {
    this.setLoadingAirport(true);
    const presenter = container.resolve(CargoPresenter);
    presenter
      .getAirportList(
        params.airportCode,
        params.cityCode,
        params.page,
        params.limit,
        params.isLocation,
        params.search
      )
      .then((res: AirportEntities) => {
        this.setAirportList(res);
        this.setErrorCause("");
      })
      .catch((error: any) =>
        this.setErrorCause(error.response ? "server" : "internet")
      )
      .finally(() => this.setLoadingAirport(false));
  }

  @Action
  public getAirportDetail(params: { airportCode: string }) {
    this.setLoadingAirport(true);
    const presenter = container.resolve(CargoPresenter);
    presenter
      .getAirportDetail(params.airportCode)
      .then((res: AirportDetail) => {
        this.setAirportDetail(res);
        this.setErrorCause("");
      })
      .catch((error: any) =>
        this.setErrorCause(error.response ? "server" : "internet")
      )
      .finally(() => this.setLoadingAirport(false));
  }
  @Action
  public getAirportDetailOrigin(params: {
    airportCode: string;
  }): Promise<boolean> {
    this.setLoadingAirport(true);
    const presenter = container.resolve(CargoPresenter);
    return presenter
      .getAirportDetail(params.airportCode)
      .then((res: AirportDetail) => {
        this.setAirportDetailOrigin(res);
        this.setErrorCause("");
        return true;
      })
      .catch((error: any) => {
        this.setErrorCause(error.response ? "server" : "internet");
        throw false;
      })
      .finally(() => this.setLoadingAirport(false));
  }
  @Action
  public getAirportDetailDestination(params: {
    airportCode: string;
  }): Promise<boolean> {
    this.setLoadingAirport(true);
    const presenter = container.resolve(CargoPresenter);
    return presenter
      .getAirportDetail(params.airportCode)
      .then((res: AirportDetail) => {
        this.setAirportDetailDestination(res);
        this.setErrorCause("");
        return true;
      })
      .catch((error: any) => {
        this.setErrorCause(error.response ? "server" : "internet");
        throw false;
      })
      .finally(() => this.setLoadingAirport(false));
  }

  @Action
  public async getAirportListOrigin(params: {
    airportCode: string;
    cityCode: string;
    page: number;
    limit: number;
    isLocation?: boolean;
    hideDefault?: boolean;
  }) {
    this.setLoadingAirport(true);
    MainAppController.showLoading();
    const presenter = container.resolve(CargoPresenter);
    return presenter
      .getAirportList(
        params.airportCode,
        params.cityCode,
        params.page,
        params.limit,
        params.isLocation
      )
      .then((res: AirportEntities) => {
        this.setAirportOriginList(res);
        this.setError(false);
        this.setErrorCause("");
        const findDefault = res.data[0];
        if (findDefault && !params.hideDefault) {
          this.setDefaultAirportOrigin({
            ...findDefault,
            airportName: `${findDefault.airportCode} - ${findDefault.airportName}`
          });
        }
        return true;
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
        return false;
      })
      .finally(() => {
        this.setLoadingAirport(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public async getAirportListDestination(params: {
    airportCode: string;
    cityCode: string;
    page: number;
    limit: number;
    isLocation?: boolean;
  }) {
    this.setLoadingAirport(true);
    MainAppController.showLoading();
    const presenter = container.resolve(CargoPresenter);
    return presenter
      .getAirportList(
        params.airportCode,
        params.cityCode,
        params.page,
        params.limit,
        params.isLocation
      )
      .then((res: AirportEntities) => {
        this.setAirportDestinationList(res);
        this.setError(false);
        this.setErrorCause("");
        const findDefault = res.data[0];
        if (findDefault) {
          this.setDefaultAirportDestination({
            ...findDefault,
            airportName: `${findDefault.airportCode} - ${findDefault.airportName}`
          });
          if (
            this.form.airports.length > 2 &&
            this.form.destinationCityCode ===
              this.form.airports[this.form.airports.length - 1].airportCode
          ) {
            this.form.airports.splice(1, 1);
          }
        }
        return true;
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
        return false;
      })
      .finally(() => {
        this.setLoadingAirport(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public getCargoSttValidation(bagOrStt: string) {
    const bagNo = !bagOrStt.match(/^\d/g) ? `${bagOrStt}-xxxxxxxxxxxxx` : "";
    return new CargoSttList(
      Array(bagNo ? 6 : 1)
        .fill("")
        .map(
          (_, index) =>
            new CargoSttValidaton(
              true,
              "",
              new CargoSttDetail({
                bagNo: bagNo,
                sttId: index,
                sttNo: `${index}${bagOrStt}`,
                sttTotalPiece: 3,
                sttGrossWeight: 30,
                sttVolumeWeight: 30,
                sttProductType: "-",
                sttCommodityCode: "all",
                sttCommodityName: "All Commodity",
                piece: [new CargoPieceDetail(index, 30, 30, index)]
              })
            )
        )
    );
  }

  @Action
  public onEditCargo(params: {
    cargoNo: string;
    actualTotalGrossWeight: number;
    actualTotalVolumeWeight: number;
    bagOrStt: EditCargoBagOrStt[];
    cargoActualPiece: number;
    actualDimension: DimensionData;
    partnerId?: number;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(CargoPresenter);
    return presenter
      .editCargo(
        new EditCargoApiRequest(
          params.cargoNo,
          params.actualTotalGrossWeight,
          params.actualTotalVolumeWeight,
          params.cargoActualPiece,
          params.bagOrStt,
          params.actualDimension,
          params.partnerId
        )
      )
      .then(res => {
        this.setError(false);
        this.setErrorCause("");
        this.setCargoIdToGeneratePdf(res.cargoNo);
        return res;
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            err,
            this.isAccountForeign
              ? "Failed to Update Cargo !"
              : "Perubahan Kargo Gagal!",
            () => this.onEditCargo(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Mutation
  dataLayerSearchCargo(params: {
    type: string;
    params?: any;
    res?: any;
    errorMessage?: string;
  }) {
    const cargoType: any = {
      plane: "Pesawat (Booking Manual),Pesawat (Booking Auto)",
      truck: "Truk (Booking Manual)",
      train: "Kereta (Booking Manual)"
    };

    switch (params.type) {
      case "success":
        dataLayer(
          "search_cargo_success",
          {
            origin_city: `${params?.params?.origin?.code} - ${params?.params?.origin?.name}`,
            destination_city: params?.params?.destination?.name,
            cargo_type_list: params.res.data.find(
              (e: any) => e.transportType === "plane"
            )
              ? params.res.data.length + 1
              : params.res.data.length,
            cargo_type: params.res.data
              .map((cargo: any) => cargoType[cargo.transportType])
              .toString()
          },
          []
        );
        break;

      case "failed":
        dataLayer(
          "search_cargo_failed",
          {
            error_message: params.errorMessage,
            origin_city: `${params?.params?.origin?.code} - ${params?.params?.origin?.name}`,
            destination_city: params?.params?.destination?.name
          },
          []
        );
        break;

      default:
        break;
    }
  }

  @Action
  getCargoOptions(params: {
    origin: { code: string; name: string };
    destination: { code: string; name: string };
    isPlaneManual: boolean;
  }) {
    const cargoInputForm: CargoInputData = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    cargoInputForm.originCityData = new OriginCityData({
      name: params.origin.name,
      code: params.origin.code
    });
    storeDatatoLocalStorage("cargo-input-data", cargoInputForm);
    this.setShowCargoOptions(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(CargoPresenter);
    presenter
      .getSearchCargoOptions(params.origin.code, params.destination.code)
      .then(res => {
        const includesPlane =
          res.data?.filter(key => key.transportType === "plane").length > 0;
        if (includesPlane && params.isPlaneManual) {
          res.data.splice(0, 0, {
            transportLabel: "Cargo Plane Manual",
            transportType: "plane-manual"
          });
        }

        const today = {
          hours: formatDate(new Date())
            ?.split(",")[1]
            ?.split(":")[0]
            ?.trim(),
          minutes: formatDate(new Date())
            ?.split(",")[1]
            ?.split(":")[1]
            ?.replace(/\bwib\b|\bwita\b|\bwit\b|\bgmt\b/gi, "")
            ?.trim()
        };
        const isAllowed =
          (+this.cutOffTime.hours < +today.hours ||
            (+this.cutOffTime.hours === +today.hours &&
              +this.cutOffTime.minutes < +today.minutes)) &&
          this.progressValue !== -1;
        if (this.accountIsForeign || (this.isAllowEditCutoff && isAllowed)) {
          //remove plane auto if account is foreign
          res.data = res.data.filter(key => key.transportType !== "plane");
        }
        this.setCargoOptions(res);
        this.setShowCargoOptions(true);
        this.setError(false);
        this.setErrorCause("");
        this.setCargoAvailable(!res.data.length);
        const tabList = res.data.map((item: any) => {
          return {
            title: cargoTypeData(item.transportType).label,
            name: item.transportType
          };
        });
        if (!params.isPlaneManual) {
          this.setCargoListTab(tabList);
          storeDatatoLocalStorage("cargo-tabs-list", tabList);
        }

        // GTM
        if (res.data.length) {
          this.dataLayerSearchCargo({ type: "success", res, params });
        } else {
          this.dataLayerSearchCargo({
            type: "failed",
            errorMessage:
              "maaf jumlah cargo yang anda pilih sudah penuh atau tidak tersedia",
            params
          });
        }
      })
      .catch(err => {
        // GTM
        this.dataLayerSearchCargo({
          type: "failed",
          errorMessage: err?.response?.data?.message?.id,
          params
        });
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            err,
            this.isAccountForeign
              ? "Cargo Search Failed!"
              : "Cari Kargo Gagal!",
            () => this.getCargoOptions(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  get accountIsForeign() {
    return AccountController.accountData.accountIsForeign;
  }

  @Action
  public getCargoProductList(params: { status: string }) {
    this.setLoadingCargoProductList(true);
    const presenter = container.resolve(CargoPresenter);
    presenter
      .getCargoProductList(params.status)
      .then((res: CargoProductEntities) => {
        this.setCargoProductList(res);
        this.setCargoProductListError(false);
        this.setCargoProductLisErrorCause("");
      })
      .catch((error: any) => {
        this.setCargoProductListError(false);
        this.setCargoProductLisErrorCause(
          error.response ? "server" : "internet"
        );
      })
      .finally(() => {
        this.setLoadingCargoProductList(false);
      });
  }

  @Action
  public appendBagOrSttNumber(params: {
    bagOrStt: BagOrSttNumber;
    cargoProductType: string;
    cargoCommodity: string;
    cargoType: string;
    callback: any;
    partnerId?: number;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(CargoPresenter);
    return presenter
      .appendBagOrSttNumber(
        new AddBagOrSttNumberRequest(
          params.bagOrStt,
          params.cargoProductType,
          params.cargoCommodity,
          params.cargoType,
          params.partnerId
        )
      )
      .then((res: ResponseBagSttDetail) => {
        this.setBagSttDetail(res);
        this.setOpenModalSuccessAppend(true);
        params.callback(res);
        return true;
      })
      .catch(error => {
        this.setErrorAppend(true);
        playNotification("error");
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            error,
            this.isAccountForeign ? "Add Failed" : "Tambah No. STT Gagal !",
            () => {
              MainAppController.closeErrorMessage();
              this.appendBagOrSttNumber(params);
            }
          )
        );
        return false;
      })
      .finally(() => {
        this.setOpenModalSuccessAppend(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public async onCreateCargo(params: {
    originAirportCode: string;
    destinationAirportCode: string;
    estTotalCargoGrossweigth: number;
    estTotalCargoVolumeweight: number;
    partnerName: string;
    partnerType: string;
    cargoNo: string;
    cargoVehicleNo: string;
    actualTotalCargoGrossweigth: number;
    actualTotalCargoVolumeweight: number;
    route: string;
    totalStt: number;
    totalPiece: number;
    cargoCommodity: string;
    productCargo: string;
    cargoType: string;
    originCityCode: string;
    destinationCityCode: string;
    departureDate: string;
    arrivalDate: string;
    bagOrStt: AddBagOrStt[];
    isCargoPlaneManual: boolean | null;
    cargoActualPiece: number;
    isFromRtc: boolean;
    rtcId: number;
    flightPlanForECargo?: FlightPlanForECargo[];
    dimensions?: DimensionPieces[];
    estDimension?: DimensionData;
    actualDimension?: DimensionData;
    nog?: string;
    rebookingId?: number;
    ngenCreatedAt?: string;
    shc?: string;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(CargoPresenter);
    return presenter
      .createCargo(
        new AddCargoRequest(
          params.originAirportCode,
          params.destinationAirportCode,
          params.estTotalCargoGrossweigth,
          params.estTotalCargoVolumeweight,
          params.partnerName,
          params.partnerType,
          params.cargoNo,
          params.cargoVehicleNo,
          params.actualTotalCargoGrossweigth,
          params.actualTotalCargoVolumeweight,
          params.route,
          params.totalStt,
          params.totalPiece,
          params.cargoCommodity,
          params.productCargo,
          params.cargoType,
          params.originCityCode,
          params.destinationCityCode,
          params.departureDate,
          params.arrivalDate,
          params.bagOrStt,
          params.isCargoPlaneManual,
          params.cargoActualPiece,
          params.isFromRtc,
          params.rtcId,
          params.flightPlanForECargo,
          params.dimensions,
          params.estDimension,
          params.actualDimension,
          params.nog,
          params.rebookingId,
          params.ngenCreatedAt,
          getHubData()?.hubId || 0,
          getHubData()?.hubName || "",
          getHubData()?.originCity || "",
          getHubData()?.hubDistrictCode || "",
          params.shc
        )
      )
      .then(res => {
        this.setError(false);
        this.setErrorCause("");
        this.setCargoIdToGeneratePdf(res.cargoNo);
        return res;
      })
      .catch(err => {
        // GTM
        const gtmBookingCargoFailed = gtmCargoData({
          type: params.cargoType,
          isAuto:
            this.form.cargoType === "plane" &&
            this.form.cargoTypeStatus === "auto"
        }).bookingCargoFailed;
        GTMBookingCargoEvent(ReadyToCargoController.eventName, {
          cargo_type: this.form.cargoType,
          "3LC_origin": LocationController.districtDetail.cityCode,
          "3LC_destination": this.form.destinationCityCode,
          booking_result: "failed",
          cargo_number: this.cargoIdToGeneratePdf,
          timestamp_booking: this.timestampBooking,
          timestamp_get_result: new Date().getTime()
        });
        dataLayer(
          gtmBookingCargoFailed.eventName,
          gtmBookingCargoFailed.property,
          ["timestamp"]
        );
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            err,
            this.isAccountForeign
              ? "Failed to Cargo Booking !"
              : "Pembuatan Gagal !",
            () => {
              MainAppController.closeErrorMessage();
              this.onCreateCargo(params);
            },
            () => {
              if (this.progressValue === -1) {
                storeDatatoLocalStorage("cargo", []);
                storeDatatoLocalStorage("show-select-airport", false);
                storeDatatoLocalStorage("cargo-bag", []);
                storeDatatoLocalStorage("event-name", "");
                storeDatatoLocalStorage("rtc-flights-data", []);
                storeDatatoLocalStorage("calculate-flight-date", []);
                storeDatatoLocalStorage("cargo-type", {});
                storeDatatoLocalStorage("is-change-total-actual-volume", false);
                this.setShowSelectAirportToLocalStorage(false);
                ReadyToCargoController.setReadyToCargoDetail(
                  new ReadyToCargo()
                );
                ReadyToCargoController.setCalculateFlightDate([]);
                ReadyToCargoController.setDetailFlightData([]);
                ReadyToCargoController.setFlowBooking("");
                this.setIsChangeVolumeActual(false);
                this.resetCargoInputForm(new CargoInputData());
                this.setSourceFromLocalStorage([]);
                this.setSTTResultFinal([]);
                this.setBagResult([]);
                this.resetForm();
                MainAppController.closeErrorMessage();
              }
            }
          )
        );
      })
      .finally(() => MainAppController.closeLoading());
  }

  get isAccountForeign() {
    return AccountController.accountData.accountIsForeign;
  }

  @Action
  public cancelCargo() {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(CargoPresenter);
    presenter
      .updateRetryCargo(
        this.cargoIdToCancelOrDelete,
        new UpdateRetryCargoRequest("cancel"),
        FlagsPermissionCargo.config_version_cancel_cargo.getValue()
      )
      .then(res => {
        this.setError(false);
        this.setErrorCause("");
        this.setCargoIdToCancelOrDelete(0);
        MainAppController.showMessageModal(
          new ModalMessageEntities({
            title: "Data Berhasil Dibatalkan",
            message: `No. Kargo ${this.cargoNoForModal} telah dibatalkan dan masuk ke dalam list Gagal/Dibatalkan`,
            textSuccess: "OK",
            onSubmit: () => {
              MainAppController.closeMessageModal();
              if (this.cancelFromDetail) {
                router.push("/cargo/booking-in-progress");
                this.setDeleteFromDetail(false);
              } else {
                this.fetchCargoList({ type: "retry" });
              }
              this.setCargoNoForModal("");
            },
            image: "cancel-success"
          })
        );
        return res;
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Gagal Batal Cargo !", () => {
            MainAppController.closeErrorMessage();
            this.cancelCargo();
          })
        );
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public deleteCargo() {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(CargoPresenter);
    presenter
      .updateRetryCargo(
        this.cargoIdToCancelOrDelete,
        new UpdateRetryCargoRequest("delete")
      )
      .then(res => {
        this.setError(false);
        this.setErrorCause("");
        this.setCargoIdToCancelOrDelete(0);
        MainAppController.showMessageModal(
          new ModalMessageEntities({
            title: "Data Berhasil Dihapus",
            message: `No. Kargo ${this.cargoNoForModal} telah dihapus`,
            textSuccess: "OK",
            onSubmit: () => {
              MainAppController.closeMessageModal();
              this.setCargoNoForModal("");
              if (this.deleteFromDetail) {
                router.push("/cargo/booking-canceled");
                this.setDeleteFromDetail(false);
              } else {
                this.fetchCargoList({ type: "retry" });
              }
            },
            image: "delete-success"
          })
        );
        return res;
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Gagal Batal Cargo !", () => {
            MainAppController.closeErrorMessage();
            this.deleteCargo();
          })
        );
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public getDownloadData(params: { id: number; type: string }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(CargoPresenter);
    return presenter
      .getDownloadData(params.id, params.type)
      .then((res: string) => {
        return {
          data: res,
          success: true
        };
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Download Gagal !", () => {
            MainAppController.closeErrorMessage();
            this.getDownloadData(params);
          })
        );
        return {
          data: "",
          success: false
        };
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Mutation
  public setFillForm(val: boolean) {
    this.isFillForm = val;
    storeDatatoLocalStorage("fill-form", val);
  }

  @Mutation
  public setIsDeleteStt(val: boolean) {
    this.isDeleteStt = val;
    storeDatatoLocalStorage("is-delete-stt", val);
  }

  @Mutation
  public setHaveBeenSubmit(bool: boolean) {
    this.isHaveBeenSubmitDetailPieces = bool;
  }

  @Mutation
  public setNotificationWeight(val: boolean) {
    this.showNotificationWeight = val;
  }

  @Mutation
  public setShowBookingForm(val: boolean) {
    this.isShowBookingForm = val;
  }

  @Mutation
  public setCargoIdToGeneratePdf(val: string) {
    this.cargoIdToGeneratePdf = val;
  }

  @Mutation
  public setCargoIdToCancelOrDelete(val: number) {
    this.cargoIdToCancelOrDelete = val;
  }

  @Mutation
  public setCargoNoForModal(val: string) {
    this.cargoNoForModal = val;
  }

  @Mutation
  public setBagSttDetail(data: ResponseBagSttDetail) {
    this.bagSttDetail = data;
  }

  @Mutation
  public setLoadingAppend(status: boolean) {
    this.isLoadingAppend = status;
  }

  @Mutation
  public setOpenModalSuccessAppend(status: boolean) {
    this.modalSuccessAppend = status;
  }

  @Mutation
  public setErrorAppend(status: boolean) {
    this.isErrorAppend = status;
  }

  @Mutation
  public setErrorCauseAppend(err: string) {
    this.errorCauseAppend = err;
  }

  @Mutation
  public setLoadingCargoProductList(status: boolean) {
    this.isLoadingCargoProductList = status;
  }

  @Mutation
  public setCargoProductList(data: CargoProductEntities) {
    this.cargoProductList = data;
  }

  @Mutation
  public setCargoProductListError(status: boolean) {
    this.cargoProductListError = status;
  }

  @Mutation
  public setCargoProductLisErrorCause(err: string) {
    this.cargoProductLisErrorCause = err;
  }

  @Mutation
  public setCargoAvailable(status: boolean) {
    this.isCargoAvailable = status;
  }

  @Mutation
  public setCargoListTab(listTab: any) {
    this.tabsList = listTab;
  }

  @Action
  public getCargoCommodityList(params: {
    page: number;
    limit: number;
    search: string;
  }) {
    this.setLoadingCargoCommodity(true);
    const presenter = container.resolve(CargoPresenter);
    return presenter
      .getCargoCommodityList(params.page, params.limit, params.search)
      .then(res => {
        this.setCargoCommodityList(res);
      })
      .finally(() => this.setLoadingCargoCommodity(false));
  }

  @Mutation
  public setCargoOptions(val: SearchCargoOptions) {
    this.cargoOptions = val;
  }

  @Mutation
  public setCargoCommodityList(val: CargoCommodityList) {
    this.cargoCommodityList = val;
  }

  @Mutation
  public setLoadingCargoCommodity(val: boolean) {
    this.isLoadingCargoCommodity = val;
  }

  @Mutation
  public setBookingDate(date?: Array<any>) {
    let isDateValid = false;
    date &&
      date.map(e => {
        if (e) isDateValid = true;
        else isDateValid = false;
      });

    this.form["estDepartureDate"] = new Date(
      isDateValid && date ? new Date(date[0]) : new Date()
    ).toISOString();
    this.form["estArrivalDate"] = new Date(
      isDateValid && date
        ? new Date(date[1])
        : new Date().setDate(new Date().getDate() + 6)
    ).toISOString();
  }

  @Mutation
  public resetForm() {
    this.showCargoOptions = false;
    this.isShowPlaneSchedule = false;
    this.form = {
      cargoType: this.cargoType.type || "",
      cargoTypeStatus: this.cargoType.status || "",
      destinationCityName:
        this.CargoInputData.destinationCityData.name ||
        this.destinationCity.name,
      destinationCityCode:
        this.CargoInputData.destinationCityData.code ||
        this.destinationCity.code,
      estDepartureDate: this.CargoInputData.estDepartureDate,
      estArrivalDate: this.CargoInputData.estArrivalDate,
      nog: this.CargoInputData.nog,
      estTotalCargoGrossWeight: this.CargoInputData.estTotalCargoGrossWeight.toString(),
      estTotalCargoVolumeWeight: this.CargoInputData.estTotalCargoVolumeWeight.toString(),
      cargoCommodityName: this.CargoInputData.cargoComodity.name ?? "",
      cargoCommodityCode: this.CargoInputData.cargoComodity.code ?? "",
      cargoProduct: this.CargoInputData.cargoProduct,
      cargoNumber: this.CargoInputData.cargoNumber,
      vehicleNumber: this.CargoInputData.vehicleNumber,
      actualTotalCargoGrossWeight: this.CargoInputData.totalActualCargoGrossWeight.toString(),
      actualTotalCargoVolumeWeight: this.CargoInputData.totalActualCargoVolumeWeight.toString(),
      actualTotalSTTPieces: this.CargoInputData.totalActualCargoColi,
      sttList: [] as CargoDetailStt[],
      totalCargoVolumeWeight: 400,
      airportOrigin: {
        airportCode:
          this.detailFlightsData && this.detailFlightsData[0]?.Route != ""
            ? this.detailFlightsData[0]?.Route.split("-")[0]
            : this.CargoInputData.airportOriginCode != ""
            ? this.CargoInputData.airportOriginCode
            : "",
        airportName: this.CargoInputData.airportOrigin.airportName,
        cityCode: this.CargoInputData.airportOrigin.cityCode,
        cityName: this.CargoInputData.airportOrigin.cityName,
        name: this.CargoInputData.airportOrigin.name,
        value:
          this.detailFlightsData && this.detailFlightsData[0]?.Route != ""
            ? this.detailFlightsData[0]?.Route.split("-")[0]
            : this.CargoInputData.airportOriginCode != ""
            ? this.CargoInputData.airportOriginCode
            : ""
      },
      airportDestination: {
        airportCode:
          this.detailFlightsData &&
          this.detailFlightsData[this.detailFlightsData.length - 1]?.Route != ""
            ? this.detailFlightsData[
                this.detailFlightsData.length - 1
              ]?.Route.split("-")[1]
            : this.CargoInputData.airportDestinationCode != ""
            ? this.CargoInputData.airportDestinationCode
            : "",
        airportName: this.CargoInputData.airportDestination.airportName,
        cityCode: this.CargoInputData.airportDestination.cityCode,
        cityName: this.CargoInputData.airportDestination.cityName,
        name: this.CargoInputData.airportDestination.name,
        value:
          this.detailFlightsData &&
          this.detailFlightsData[this.detailFlightsData.length - 1]?.Route != ""
            ? this.detailFlightsData[
                this.detailFlightsData.length - 1
              ]?.Route.split("-")[1]
            : this.CargoInputData.airportDestinationCode != ""
            ? this.CargoInputData.airportDestinationCode
            : ""
      },
      flightsData: ReadyToCargoController.detailFlightsData,
      airports: [],
      transit: "",
      isChangeAirport: false,
      estDimension: {
        length: 0,
        width: 0,
        height: 0
      },
      actualDimension: {
        length: this.CargoInputData.dimension.length,
        width: this.CargoInputData.dimension.width,
        height: this.CargoInputData.dimension.height
      },
      ngenCreatedAt: "",
      isRtc: false,
      thresholdTime: this.CargoInputData.thresholdTime,
      shcCode: this.CargoInputData.cargoComodity.shcCode,
      shcName: this.CargoInputData.cargoComodity.shcName
    };
  }

  @Mutation
  public resetModalPlaneAuto() {
    this.form.airportOrigin = {
      airportCode: "",
      airportName: "",
      cityCode: "",
      cityName: "",
      name: "",
      value: ""
    };
    this.form.transit = "";
    NGenController.setAwbDetailData(new AwbDetailData());
  }

  @Mutation
  public setShowCargoOptions(val: boolean) {
    this.showCargoOptions = val;
  }

  @Mutation
  public setAirportsPopupModel(val: any) {
    this.airportsPopupModel = val;
  }

  @Mutation
  public setChangePlaneSchedule(val: boolean) {
    this.isChangePlaneSchedule = val;
  }

  @Mutation
  public setPlaneBooking(val: boolean) {
    this.isPlaneBooking = val;
  }

  @Mutation
  public setShowPlaneSchedule(val: boolean) {
    this.isShowPlaneSchedule = val;
    this.isShowPlaneScheduleFromLocalStorage = val;
    storeDatatoLocalStorage("setShowPlaneSchedule", val);
  }

  @Mutation
  public onReset() {
    this.advanceFilterData = {
      type: { name: "", value: "" },
      cityOriginCode: "",
      cityDestinationCode: "",
      airport: ""
    };
    this.search = "";
    this.periodeStart = null;
    this.periodeEnd = null;
  }

  @Mutation
  private setCargoList(data: CargoEntities) {
    this.cargoData = data;
  }

  @Mutation
  private setRetryCargo(data: CargoEntities) {
    this.retryCargo = data;
  }

  @Mutation
  private setAirportList(data: AirportEntities) {
    this.airportData = data;
  }

  @Mutation
  private setAirportDetail(data: AirportDetail) {
    this.airportDetail = data;
  }
  @Mutation
  private setAirportDetailOrigin(data: AirportDetail) {
    this.airportDetailOrigin = data;
  }
  @Mutation
  private setAirportDetailDestination(data: AirportDetail) {
    this.airportDetailDestination = data;
  }

  @Mutation
  private setAirportOriginList(data: AirportEntities) {
    this.airportDataOrigin = data;
  }

  @Mutation
  private setAirportDestinationList(data: AirportEntities) {
    this.airportDataDestination = data;
  }

  @Mutation
  private setDefaultEmptyAirport() {
    this.form.airports.push(null);
  }

  @Mutation
  public setDefaultAirportOrigin(res: AirportData) {
    const data = {
      airportCode: res.airportCode,
      airportName: res.airportName,
      cityCode: res.cityCode,
      cityName: firstCapitalize(res.cityName?.toLowerCase()),
      name: res.name,
      value: res.airportCode
    };
    this.form.airports.push(data);
    this.form.airportOrigin = data;
    this.cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    this.cargoInputForm.airportOrigin.airportName = data.airportName;
    this.cargoInputForm.airportOrigin.cityCode = data.cityCode;
    this.cargoInputForm.airportOrigin.cityName = data.cityName;
    this.cargoInputForm.airportOrigin.name = data.name;
    storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
  }

  @Mutation
  public setResetDefaultAirportOrigin() {
    const data = {
      airportCode: "",
      airportName: "",
      cityCode: "",
      cityName: "",
      name: "",
      value: ""
    };
    this.form.airports.length = 0;
    this.form.airportOrigin = data;
    this.cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    this.cargoInputForm.airportOrigin.airportName = data.airportName;
    this.cargoInputForm.airportOrigin.cityCode = data.cityCode;
    this.cargoInputForm.airportOrigin.cityName = data.cityName;
    this.cargoInputForm.airportOrigin.name = data.name;
    storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
  }

  @Mutation
  private setDefaultAirportDestination(res: AirportData) {
    const data = {
      airportCode: res.airportCode,
      airportName: res.airportName,
      cityCode: res.cityCode,
      cityName: firstCapitalize(res.cityName?.toLowerCase()),
      name: res.airportName,
      value: res.airportCode
    };
    this.form.airports.push(data);
    this.form.airportDestination = data;
    this.cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    this.cargoInputForm.airportDestination.airportName = data.airportName;
    this.cargoInputForm.airportDestination.cityCode = data.cityCode;
    this.cargoInputForm.airportDestination.cityName = data.cityName;
    this.cargoInputForm.airportDestination.name = data.name;
    storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
  }

  @Mutation
  public setSTTResultFinal(data: CargoDetailStt[]) {
    this.sttResultFinal = data;
    storeDatatoLocalStorage("cargo", data);
  }

  @Mutation
  public setBagResult(data: any) {
    this.bagResult = data;
  }

  @Mutation
  private setCargoDetailData(data: CargoData) {
    this.cargoDetailData = data;
    const newCargoInputData = new CargoInputData({
      id: data.id,
      dimension: new DimensionData({
        length: data.actualDimension.length,
        width: data.actualDimension.width,
        height: data.actualDimension.height
      }),
      totalActualCargoGrossWeight: Number(
        data.totalActualCargoGrossWeight.toString().replace(",", ".")
      ),
      calcuateActualCargoGrossWeight: Number(
        data.totalActualCargoGrossWeight.toString().replace(",", ".")
      ), // explanation https://github.com/Lionparcel/medusa/pull/5753/files#r1285591503
      totalActualCargoVolumeWeight: Number(
        data.totalActualCargoVolumeWeight.toString().replace(",", ".")
      ),
      totalActualCargoColi: data.totalPieces,
      originCityData: new OriginCityData({
        code: data.originCityCode,
        name: data.originCityName
      }),
      nog: data.nog,
      cargoComodity: new OptionsClass({
        name: data.cargoCommodity,
        code: data.cargoCommodityCode
      }),
      cargoProduct: data.cargoProduct,
      estTotalCargoVolumeWeight: data.totalEstCargoVolumeWeight,
      estTotalCargoGrossWeight: data.totalEstCargoGrossWeight,
      flightData: this.detailFlightsData,
      isFromRebookingRTC: data.isFromRtc
    });
    storeDatatoLocalStorage("cargo-input-data", newCargoInputData);

    // add bag number to localstorage
    const groupBaggingNumber = groupBy(data.detailStt, "baggingNumber");
    const dataBagging = [];
    for (const [key, value] of Object.entries(groupBaggingNumber)) {
      if (key) {
        dataBagging.push({
          baggingNumber: key,
          bagGrossWeight: value[0].bagCustomGrossWeight || null,
          isFromRebooking: true,
          stt: value
        });
      }
    }
    storeDatatoLocalStorage("cargo-bag", dataBagging);
    this.bagResult = dataBagging as any;
  }

  @Mutation
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private setLoadingPrint(isLoading: boolean) {
    this.isLoadingPrint = isLoading;
  }

  @Mutation
  public setErrorPrint(isLoading: boolean) {
    this.isErrorPrint = isLoading;
  }

  @Mutation
  public async setAdvanceFilterData(value: any) {
    this.advanceFilterData = value;
  }

  @Mutation
  public setSearch(val: string) {
    this.search = val;
  }

  @Mutation
  public setStartDate(val: any) {
    this.periodeStart = val;
  }

  @Mutation
  public setEndDate(val: any) {
    this.periodeEnd = val;
  }

  @Mutation
  public setOpenModalTopup(boolean: boolean) {
    this.isOpenModalTopup = boolean;
  }

  @Mutation
  public setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  public setErrorCause(str: string) {
    this.errorCause = str;
  }

  @Action
  async getRegionBaggingGrouping(code: string) {
    if (code) {
      try {
        this.setLoadingRegion(true);
        MainAppController.closeErrorMessage();
        const res = await BaggingController.getRegionBaggingGrouping(code);
        this.setRegionBaggingGrouping(res);
        this.setShowNotesDestination(true);
      } catch (error) {
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            error,
            "Gagal Mendapatkan Region Bagging Grouping",
            () => this.getRegionBaggingGrouping(code)
          )
        );
        this.setShowNotesDestination(false);
      } finally {
        this.setLoadingRegion(false);
      }
    }
  }

  @Mutation
  private async setFirst() {
    this.pagination.page = 1;
    this.cargoData.pagination.page = 1;
    this.retryCargo.pagination.page = 1;
  }

  @Action
  public async setFirstPage() {
    await this.setFirst();
  }

  @Action
  public setDefaultFlightData() {
    this.form.airports = [];
    this.form.airportOrigin = {
      airportCode: "",
      airportName: "",
      cityCode: "",
      cityName: "",
      name: "",
      value: ""
    };
    this.form.airportDestination = {
      airportCode: "",
      airportName: "",
      cityCode: "",
      cityName: "",
      name: "",
      value: ""
    };
    this.form.flightsData = [];
  }

  @Action
  public mappingReadyToCargo() {
    if (
      this.readyToCargoDetail.id !== 0 &&
      this.readyToCargoDetail.detail.length &&
      !this.sourceSttFromLocalStorage.length
    ) {
      this.setSTTResultFinal(this.readyToCargoDetail.detail);
      this.setSourceFromLocalStorage(this.readyToCargoDetail.detail);
      const groupBaggingNumber = groupBy(
        this.readyToCargoDetail.detail,
        "baggingNumber"
      );
      const data = [];
      for (const [key, value] of Object.entries(groupBaggingNumber)) {
        if (key) {
          data.push({
            baggingNumber: key,
            bagGrossWeight: value[0].bagCustomGrossWeight || null,
            stt: value
          });
        }
      }
      storeDatatoLocalStorage("cargo-bag", data);
      this.setBagResult(data);
    }
    this.setSTTResultFinal(this.groupedSTTResultFinal);
    if (this.form.flightsData.length > 0) {
      this.form.airportOrigin.airportCode =
        this.form.flightsData[0].airportCodeOrigin ||
        this.form.airports[0].airportCode;
      this.form.airportOrigin.value =
        this.form.flightsData[0].airportCodeOrigin ||
        this.form.airportOrigin.airportCode;
      this.form.airportDestination.airportCode =
        this.form.flightsData[this.form.flightsData.length - 1]
          .airportCodeDestination ||
        this.form.airports[this.form.airports.length - 1].airportCode;
      this.form.airportDestination.value =
        this.form.flightsData[this.form.flightsData.length - 1]
          .airportCodeDestination || this.form.airportDestination.airportCode;
      // set airportCode to loalstorage
      const cargoInputForm = JSON.parse(
        localStorage.getItem("cargo-input-data") || "{}"
      );
      cargoInputForm.airportOriginCode =
        this.form.flightsData[0].airportCodeOrigin ||
        this.form.airports[0]?.airportCode;
      cargoInputForm.airportDestinationCode =
        this.form.flightsData[this.form.flightsData.length - 1]
          .airportCodeDestination ||
        this.form.airports[this.form.airports.length - 1].airportCode;
      storeDatatoLocalStorage("cargo-input-data", cargoInputForm);
    }
  }

  @Action
  public async autoFillDestination(detailSTT: any) {
    if (detailSTT) {
      if (!this.destinationCity?.code) {
        await this.getCityList(detailSTT);
        if (!this.regionBaggingGrouping.baggingRegionDestinationId) {
          this.getRegionBaggingGrouping(this.destinationCity?.code);
        }
      }
    }
  }

  @Action
  async getCityList(detailSTT: any) {
    const region = detailSTT.regionId || detailSTT.region_id;
    const destinationCityCode =
      detailSTT.destination_city_code || detailSTT.destinationCityCode;
    await LocationController.getCityList({
      search:
        this.dataProfile.account_location.city_code === region ||
        this.regionBaggingGrouping.baggingRegionOriginId === region
          ? destinationCityCode
          : region ?? destinationCityCode,
      status: "",
      page: 1,
      limit: 10
    });
    this.setDestinationCity(
      region || destinationCityCode ? this.optionDestinationCity[0] : ""
    );
  }

  @Action
  public mappingCommodityOptions() {
    this.setDisableCommodityGroup(false);

    // mapping each bagging's stt
    const mappingdata: CargoDetailStt[] = [];
    for (const item of this.sttResultFinal as any) {
      if (item?.sttDetails?.length) {
        for (const detail of item.sttDetails) {
          mappingdata.push(detail);
        }
      } else {
        mappingdata.push(item);
      }
    }

    // filter result of mapping data by commodity group
    const getUniqueList: any = uniqBy(
      mappingdata.filter((item: CargoDetailStt) => item.commodityGroupCode),
      "commodityGroupCode"
    );

    // count total duplicate commodity group
    const countGroupByCommodity = mappingdata.reduce(
      (accumulator: any, item: CargoDetailStt) => {
        return (
          (accumulator[item.commodityGroupName] =
            (accumulator[item.commodityGroupName] || 0) + 1),
          accumulator
        );
      },
      {}
    );

    // convert object total to array of object
    const totalDataByCommodityGroup = Object.entries(
      countGroupByCommodity
    ).map((item: any[]) => ({ name: item[0], total: item[1] }));

    // find max of total
    const max = totalDataByCommodityGroup.reduce((prev: any, current: any) =>
      prev.total > current.total ? prev : current
    );

    // find all index if possible max is more than 1
    const maxIndexLocation = totalDataByCommodityGroup.reduce(
      (accumulator: any, currentValue, currentIndex) =>
        currentValue.total === max.total
          ? accumulator.concat(currentIndex)
          : accumulator,
      []
    );

    const commodityList = getUniqueList.map(
      (item: CargoDetailStt) =>
        new OptionsClass({
          name: item.commodityGroupName,
          value: item.commodityGroupCode,
          shcCode: item.shcCode,
          shcName: item.shcName
        })
    );

    // if only one max, will set default value
    if (maxIndexLocation.length === 1) {
      // get detail data of max
      const dominanData: CargoDetailStt = getUniqueList[maxIndexLocation[0]];

      // set to default value
      this.setCommodityItem(
        new OptionsClass({
          name: dominanData.commodityGroupName,
          code: dominanData.commodityGroupCode,
          shcCode: dominanData?.shcCode,
          shcName: dominanData?.shcName
        })
      );
      this.form.cargoCommodityName = dominanData.commodityGroupName;
      this.form.cargoCommodityCode = dominanData.commodityGroupCode || "";
      this.form.shcName = dominanData?.shcName || "";
      this.form.shcCode = dominanData?.shcCode || "";
      this.setDisableCommodityGroup(true);
    }
    // if more than one max, will be empty and user select commodity
    else {
      const cargoInputForm = JSON.parse(
        localStorage.getItem("cargo-input-data") || "{}"
      );

      if (!cargoInputForm.cargoComodity?.name) {
        this.setCommodityItem(new OptionsClass());
        this.form.cargoCommodityName = "";
        this.form.cargoCommodityCode = "";
        this.form.shcName = "";
        this.form.shcCode = "";
      }

      // if no dominan (draw) total
      this.setCommodityItem(
        new OptionsClass({
          name: commodityList[0].name,
          code: commodityList[0].value,
          shcCode: commodityList[0].shcCode,
          shcName: commodityList[0].shcName
        })
      );
      this.form.cargoCommodityCode = commodityList[0].value;
      this.form.cargoCommodityName = commodityList[0].name;
      this.form.shcName = commodityList[0].shcName || "";
      this.form.shcCode = commodityList[0].shcCode || "";
    }

    this.setCommodityOptions(commodityList);
  }

  @Action
  calculationVolumeWeight(totalPcs: number) {
    const dimensionDivider =
      this.form.cargoType === "plane" ? this.dimensionDivider : 6000;
    const total =
      ((this.form.actualDimension.length *
        this.form.actualDimension.width *
        this.form.actualDimension.height) /
        dimensionDivider) *
      totalPcs;
    this.form.actualTotalCargoVolumeWeight =
      total < 1 ? "1" : Math.round(total).toString();
    const totalActualCargoVolumeWeight = Number(
      this.form.actualTotalCargoVolumeWeight
    );
    this.setOnChangeTotalCargoVolumeWeight(totalActualCargoVolumeWeight);
  }

  @Mutation
  public setPeriodeStart(value: Date | null) {
    this.periodeStart = value;
  }

  @Mutation
  public setDestinationCity(value: any) {
    if (value) {
      this.form.destinationCityName = value?.name || "";
      this.form.destinationCityCode = value?.code || "";

      const destination = new DestinationCityData({
        id: value.id,
        code: value.code,
        name: value.name,
        label: `${value.code} - ${value.name}`
      });
      const cargoInputForm: CargoInputData = JSON.parse(
        localStorage.getItem("cargo-input-data") || "{}"
      );
      cargoInputForm.destinationCityData = destination;
      storeDatatoLocalStorage("cargo-input-data", cargoInputForm);
    }
    this.destinationCity = value;
  }

  @Mutation
  public setResetDestinationCity() {
    this.destinationCity = "";
    this.form.destinationCityCode = "";
    this.form.destinationCityName = "";
  }

  @Mutation
  public setPeriodeEnd(value: Date | null) {
    this.periodeEnd = value;
  }

  @Mutation
  public setSttFailed(val: any[]) {
    this.sttFailed = val;
  }

  @Mutation
  public setFailed(val: boolean) {
    this.isFailed = val;
  }

  @Mutation
  public setLoadingAirport(val: boolean) {
    this.isLoadingAirport = val;
  }

  @Mutation
  public setOpenSuccess(val: boolean) {
    this.isOpenSuccess = val;
  }

  @Mutation
  public setFullBooked(val: boolean) {
    this.isFullBooked = val;
  }

  public showSelectAirport = false;

  @Mutation
  public setShowSelectAirport(bool: boolean) {
    this.showSelectAirport = bool;
  }

  @Mutation
  public setShowSelectAirportToLocalStorage(bool: boolean) {
    this.showSelectAirportFromLocalStorage = bool;
    storeDatatoLocalStorage("show-select-airport", bool);
  }

  @Mutation
  public setCargoType(value: any) {
    this.cargoType = {
      type: value.type,
      status: value.status
    };
    this.form.cargoType = value.type;
    this.form.cargoTypeStatus = value.status;
    storeDatatoLocalStorage("cargo-type", value);
  }

  @Mutation
  public setSourceFromLocalStorage(data: CargoDetailStt[]) {
    this.sourceSttFromLocalStorage = data;
  }

  @Mutation
  public setListLastReadCargo(data: ListLastReadCargo[]) {
    this.listLastReadCargo = data;
  }

  @Mutation
  public setProgressValue(value: number) {
    this.progressValue = value;
    storeDatatoLocalStorage("progress-value", value);
  }

  @Mutation
  public setIsModalGamification(value: boolean) {
    this.isShowModalGamification = value;
    storeDatatoLocalStorage("is-modal-gamification", value);
  }

  @Mutation
  public setIsModalGamificationDone(value: boolean) {
    this.isModalGamificationDone = value;
    storeDatatoLocalStorage("is-modal-gamification-done", value);
  }

  @Mutation
  public setIsChangeVolumeActual(value: boolean) {
    this.isChangeVolumeActual = value;
    storeDatatoLocalStorage("is-change-total-actual-volume", value);
  }
  @Mutation
  public setIsFromRTC(value: boolean) {
    this.isFromRtc = value;
  }

  @Mutation
  public setTimestampBooking(value: number) {
    this.timestampBooking = value;
  }

  @Mutation
  public setLoadingRegion(data: boolean) {
    this.loadingRegion = data;
  }

  @Mutation
  public setShowNotesDestination(data: boolean) {
    this.showNotesDestination = data;
  }

  @Mutation
  public setRegionBaggingGrouping(data: RegionBaggingGrouping) {
    this.regionBaggingGrouping = data;
  }

  @Mutation
  public setCargoStatus(value: string) {
    this.cargoStatus = value;
  }

  @Mutation
  public setCancelFromDetail(boolean: boolean) {
    this.cancelFromDetail = boolean;
  }

  @Mutation
  public setDeleteFromDetail(boolean: boolean) {
    this.deleteFromDetail = boolean;
  }

  @Mutation
  public setCacheCommodityGroup(boolean: boolean) {
    this.isCacheCommodityGroup = boolean;
  }

  @Mutation
  public setDestinationCityRTC(param: any) {
    this.destinationCityRTC = param;
  }

  @Mutation
  public setClientValue(param: any) {
    this.clientValue = param;
  }

  @Mutation
  public setReadyToCargoList(param: ReadyToCargoListEntities) {
    this.readyToCargoList = param;
  }

  @Mutation
  public setActiveTabs(param: string) {
    this.activeTabs = param;
  }

  @Mutation
  public setCargoInputForm(obj: CargoInputData) {
    this.cargoInputForm = obj;
    this.CargoInputData = obj;
  }

  @Action
  public setTotalActualGrossWeight(value: number) {
    const cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    cargoInputForm.totalActualCargoGrossWeight = Number(
      value.toString().replace(",", ".")
    );
    cargoInputForm.calcuateActualCargoGrossWeight = Number(
      value.toString().replace(",", ".")
    ); // explanation https://github.com/Lionparcel/medusa/pull/5753/files#r1285584448
    this.CargoInputData.totalActualCargoGrossWeight = Number(
      value.toString().replace(",", ".")
    );
    this.form.actualTotalCargoGrossWeight = value.toString();
    storeDatatoLocalStorage("cargo-input-data", cargoInputForm);
  }

  @Mutation
  public setOnChangeTotalActualGrossWeight(value: number) {
    this.cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    this.cargoInputForm.totalActualCargoGrossWeight = Number(
      value.toString().replace(",", ".")
    );
    this.form.actualTotalCargoGrossWeight = value.toString();
    storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
    this.isErrorInputStepThree.grossWeight = !+Number(
      this.form.actualTotalCargoGrossWeight.replace(",", ".")
    );
  }

  @Mutation
  public setOnChangeThresholdTime(value: number) {
    storeDatatoLocalStorage("is-change-threshold-time", true);
    this.cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    this.cargoInputForm.thresholdTime = Number(
      value.toString().replace(",", ".")
    );
    this.form.thresholdTime = value;
    storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
  }

  @Mutation
  public setOnChangeDimension({ length, width, height }: DimensionData) {
    this.cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    this.cargoInputForm.dimension = new DimensionData({
      length: length,
      width: width,
      height: height
    });
    this.form.actualDimension.height = height;
    this.form.actualDimension.length = length;
    this.form.actualDimension.width = width;
    storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
    this.isErrorInputStepThree.dimension = !this.form.actualDimension.width || !this.form.actualDimension.length || !this.form.actualDimension.height;
  }

  @Mutation
  public setOnChangeTotalColi(value: number) {
    this.cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    this.cargoInputForm.totalActualCargoColi = Number(value);
    this.form.actualTotalSTTPieces = value;
    storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
    this.isErrorInputStepThree.totalPieces = !+this.form.actualTotalSTTPieces;
    if (
      +this.formDetailPieces.totalPieces !== +this.form.actualTotalSTTPieces &&
      this.isHaveBeenSubmitDetailPieces
    ) {
      this.isErrorDiffPiecesActualThanDimensionPieces = true;
      return;
    }
    this.isErrorDiffPiecesActualThanDimensionPieces = false;
  }

  @Mutation
  public resetTotalColiOnLocalStorage() {
    this.cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    this.cargoInputForm.totalActualCargoColi = 0;
    storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
  }

  @Mutation
  public setOnChangeTotalCargoVolumeWeight(value: number) {
    this.cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    this.cargoInputForm.totalActualCargoVolumeWeight = Number(
      value.toString().replace(",", ".")
    );
    this.CargoInputData.totalActualCargoVolumeWeight = Number(
      value.toString().replace(",", ".")
    );
    this.form.actualTotalCargoVolumeWeight = value.toString();
    storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
    this.isErrorInputStepThree.volumeWeight = !+Number(
      this.form.actualTotalCargoVolumeWeight.replace(",", ".")
    );
  }

  @Mutation
  public resetErrorDiffPiecesActualThanDimensionPieces() {
    this.isErrorDiffPiecesActualThanDimensionPieces = false;
  }

  get errorInputStepThree() {
    let result = false;
    Object.entries(this.isErrorInputStepThree).forEach(([, value]) => {
      if (value) {
        result = value;
      }
    });
    return result;
  }

  @Mutation
  public setResetErrorInputStepThree() {
    this.isErrorInputStepThree = {
      grossWeight: false,
      dimension: false,
      totalPieces: false,
      volumeWeight: false
    };
  }

  @Mutation
  public setCargoComodity(value: OptionsClass) {
    this.cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );

    this.cargoInputForm.cargoComodity.name = value?.name ?? "";
    this.cargoInputForm.cargoComodity.code = value.code || value.value || "";
    this.cargoInputForm.cargoComodity.shcCode = value.shcCode || "";
    this.cargoInputForm.cargoComodity.shcName = value.shcName || "";
    this.form.cargoCommodityName = value.name ?? "";
    this.form.cargoCommodityCode = value.code || value.value || "";
    this.form.shcName = value.shcName || "";
    this.form.shcCode = value.shcCode || "";
    this.commodityItem = new OptionsClass({
      name: value.name ?? "",
      code: value.code || value.value || "",
      shcName: value.shcName || "",
      shcCode: value.shcCode || ""
    });
    storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
  }

  @Mutation
  public setDisableCommodityGroup(value: boolean) {
    this.disableCommodityGroup = value;
  }

  @Mutation
  public setOnChangeNog(value: string) {
    this.cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    this.cargoInputForm.nog = value;
    this.form.nog = value;
    storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
  }

  @Mutation
  public setOnSelectCargoProduct(value: string) {
    this.cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    this.cargoInputForm.cargoProduct = value;
    this.CargoInputData.cargoProduct = value;
    this.form.cargoProduct = value;
    storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
  }

  @Mutation
  public setOnChangeVehicleNumber(value: string) {
    const cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    cargoInputForm.vehicleNumber = value;
    this.CargoInputData.vehicleNumber = value;
    this.form.vehicleNumber = value;
    storeDatatoLocalStorage("cargo-input-data", cargoInputForm);
  }

  @Mutation
  public setIsChangeCargoType(value: boolean) {
    this.isChangeCargoType = value;
    storeDatatoLocalStorage("cargo-is-change-cargo-type", value);
  }

  @Mutation
  public setUpdateFromBookingCargoNumber(value: string) {
    this.cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    this.cargoInputForm.cargoNumber = value;
    this.form.cargoNumber = value;
    storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
  }

  @Mutation
  public setEstimationTotalVolumeWeight(value: any) {
    this.cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    this.cargoInputForm.estTotalCargoVolumeWeight = Number(
      value.toString().replace(",", ".")
    );
    storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
  }

  @Mutation
  public setEstimationTotalGrossWeight(value: any) {
    this.cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    this.cargoInputForm.estTotalCargoGrossWeight = Number(
      value.toString().replace(",", ".")
    );
    storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
  }

  @Mutation
  public setFlightData(value: FlightDetail) {
    const detailFlightsData = JSON.parse(
      localStorage.getItem("rtc-flights-data") || "[]"
    );
    detailFlightsData.push(value);
    this.form.flightsData.push(value);
    storeDatatoLocalStorage("rtc-flights-data", detailFlightsData);
  }

  @Mutation
  public setEditFlightData(value: FlightDetail[]) {
    storeDatatoLocalStorage("rtc-flights-data", value);
  }

  @Mutation
  public resetFlightData() {
    this.form.flightsData.length = 0;
    storeDatatoLocalStorage("rtc-flights-data", []);
  }

  @Mutation
  public resetCargoInputForm(obj: CargoInputData) {
    storeDatatoLocalStorage("cargo-input-data", obj);
    this.cargoInputForm = obj;
    this.form.actualTotalCargoVolumeWeight = "0";
    this.form.destinationCityName = "";
    this.form.destinationCityCode = "";
    this.form.vehicleNumber = "";
    this.form.cargoProduct = "";
    this.form.cargoNumber = "";
    this.form.actualDimension = {
      length: obj.dimension.length,
      width: obj.dimension.width,
      height: obj.dimension.height
    };
    this.destinationCity = "";
    this.calculateTotalGrossWeight = 0;
    this.cargoInputForm = obj;
    this.CargoInputData = obj;
    this.isFillForm = false;
    this.cargoType = {};
  }

  @Mutation
  public resetTotalActualGrossWeight() {
    this.cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    this.cargoInputForm.totalActualCargoGrossWeight = 0;
    this.CargoInputData.totalActualCargoVolumeWeight = 0;
    this.form.actualTotalCargoGrossWeight = "0";
    storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
  }

  @Mutation
  public resetIsEditReadyToCargo() {
    this.isEditReadyToCargo = false;
  }

  @Mutation
  public setShowModalCargoType(value: boolean) {
    this.showModalCargoType = value;
    storeDatatoLocalStorage("show-modal-cargo", value);
  }

  @Mutation
  public setFormDetailPieces(form: any) {
    this.formDetailPieces = form;
  }

  @Mutation
  public setShowPopupDimensionPieces(value: boolean) {
    this.showPopupDimensionPieces = value;
  }

  @Mutation
  public setIsEditReadyToCargo(value: boolean) {
    this.isEditReadyToCargo = value;
    storeDatatoLocalStorage("isEditReadyToCargo", value);
  }

  @Mutation
  public setPlaneAutoFlow(value: boolean) {
    this.isPlaneAutoFlow = value;
  }

  @Mutation
  public setCommodityItem(value: OptionsClass) {
    this.commodityItem = value;
  }

  @Mutation
  public setCommodityOptions(value: OptionsClass[]) {
    this.commodityOptions = value;
  }

  withPrintCutOff = false;
  withPrintCutOffLabel = false;

  @Mutation
  public setWithPrintCutOff(value: boolean) {
    this.withPrintCutOff = value;
  }

  @Mutation
  public setWithPrintLabelCutOff(value: boolean) {
    this.withPrintCutOffLabel = value;
  }

  @Mutation
  public setLastReadCargoSession(value: any) {
    this.lastReadCargoSession = value;
    storeDatatoLocalStorage("last-read-cargo", value);
  }

  @Mutation
  public setIsFromRebookingRTC(value: boolean) {
    this.isRebookingFromRTC = value;
    storeDatatoLocalStorage("is-from-rebooking-rtc", value);
  }

  get readyToCargoDetail() {
    return ReadyToCargoController.readyToCargoDetail;
  }

  get optionDestinationCity() {
    return LocationController.cityData.cityData.map(
      item =>
        new OptionsClass({
          id: item.id,
          label: `${item.code} - ${firstCapitalize(item.name?.toLowerCase())}`,
          code: item.code,
          name: capitalize(item.name?.toLowerCase())
        })
    );
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get totalSttPieces() {
    // STTs with the same bagNo counted as one, STTs without bagNo counted per totalPieces
    return this.sttResultFinal
      .map((key: any) =>
        key.baggingNumber && key.sttDetails.length ? 1 : key.totalPieces
      )
      .reduce((a, b) => a + b, 0);
  }

  get isPlaneAuto() {
    return (
      this.form.cargoType === "plane" && this.form.cargoTypeStatus === "auto"
    );
  }

  get totalGrossWeights() {
    const total = convertDecimalWithComma(
      this.sourceSttFromLocalStorage
        .map((key: any) => {
          if (key.baggingNumber && key.sttDetails) {
            return key.sttDetails.reduce((a: any, b: any) => {
              return a + b.grossWeigth;
            }, 0);
          } else {
            return key.grossWeigth;
          }
        })
        .reduce((a, b) => {
          return a + b;
        }, 0),
      2
    );
    if (!this.isPlaneAuto) {
      this.form["estTotalCargoGrossWeight"] = String(total);
    }
    return total;
  }

  isFromCutOff = false;
  @Mutation
  public setIsFromCutOff(boolean: boolean) {
    this.isFromCutOff = boolean;
  }

  get totalVolumeWeights() {
    const total = convertDecimalWithComma(
      this.sourceSttFromLocalStorage
        .map((key: any) => {
          if (key.baggingNumber && key.sttDetails) {
            return key.sttDetails.reduce((a: any, b: any) => {
              return a + b.volumeWeight;
            }, 0);
          } else {
            return key.volumeWeight;
          }
        })
        .reduce((a, b) => {
          return a + b;
        }, 0),
      2
    );
    if (!this.isPlaneAuto) {
      this.form["estTotalCargoVolumeWeight"] = String(total);
    }
    return total;
  }

  get groupedSTTResultFinal() {
    const tempData: any = [];
    if (this.sourceSttFromLocalStorage) {
      this.sourceSttFromLocalStorage.forEach((e: any) => {
        const existingIdx = tempData.findIndex(
          (a: any) => a.baggingNumber === e.baggingNumber
        );
        if (e.baggingNumber && !e.sttDetails) {
          if (existingIdx > -1) {
            tempData[existingIdx]["sttDetails"].push(e);
          } else {
            tempData.push({ ...e, sttDetails: [e] });
          }
          return;
        }
        tempData.push(e);
      });
    }
    return tempData;
  }

  get dimensionDivider() {
    return FlagsPermissionCargo.dimension_divider.getValue();
  }

  get isAllowEditCutoff() {
    const isEditRtcCutoff =
      ReadyToCargoController.flowBooking === "rtc-edit" &&
      this.cutOffTime.hours !== undefined &&
      this.cutOffTime.minutes !== undefined;
    return isEditRtcCutoff;
  }

  get cutOffTime() {
    return {
      hours: ReadyToCargoController.readyToCargoDetail.cutOffTime?.split(
        ":"
      )[0],
      minutes: ReadyToCargoController.readyToCargoDetail.cutOffTime?.split(
        ":"
      )[1]
    };
  }
}

export const CargoController = getModule(CargoStore);
