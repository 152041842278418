import { UrgentDeliveryManagementMapper } from "@/data/persistences/mappers/UrgentDeliveryManagementMapper";
import ApiService from "../../services/ApiService";
import { Endpoints } from "../../misc/Endpoints";
import {
  UrgentDeliveryManagementPODListRequest,
  UrgentDeliveryManagementPODList,
  UrgentDeliveryManagementRTSList,
  UrgentDeliveryManagementRTSListRequest,
  UrgentDeliveryManagementHALList,
  UrgentDeliveryManagementHALListRequest,
  UrgentDeliveryManagementPODListRequestDownload,
  UrgentDeliveryManagementRTSListRequestDownload,
  UrgentDeliveryManagementHALListRequestDownload,
  UrgentDeliveryManagementTakenByRecipientList,
  UrgentDeliveryManagementDetailData,
} from "@/domain/entities/UrgentDeliveryManagement";
import { UrgentDeliveryManagementRepositoryInterface } from "@/data/persistences/repositories/contracts/UrgentDeliveryManagementRepositoryInterface";
import { downloadFile } from "../../misc/Utils";
import { AxiosResponse } from "axios";

export class UrgentDeliveryManagementApiRepository
  implements UrgentDeliveryManagementRepositoryInterface {
  private service: ApiService;
  private mapper: UrgentDeliveryManagementMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: UrgentDeliveryManagementMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  async getPODList(
    params: UrgentDeliveryManagementPODListRequest
  ): Promise<UrgentDeliveryManagementPODList> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getUrgentDeliveryManagementPODList(params)
    );

    return this.mapper.convertPODListFromApi(resp);
  }

  async downloadUrgentPODList(
    params: UrgentDeliveryManagementPODListRequestDownload
  ): Promise<void> {
    return downloadFile(
      `${Endpoints.baseUrl}${this.endpoints.downloadUrgentManagementList('pod', params)}`,
      'Urgent_POD_LIST',
      '.xlsx',
      true
    )
  }

  async getRTSList(
    params: UrgentDeliveryManagementRTSListRequest
  ): Promise<UrgentDeliveryManagementRTSList> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getUrgentDeliveryManagementRTSList(params)
    );
    return this.mapper.convertRTSListFromApi(resp);
  }

  async downloadUrgentRTSList(
    params: UrgentDeliveryManagementRTSListRequestDownload
  ): Promise<void> {
    return downloadFile(
      `${Endpoints.baseUrl}${this.endpoints.downloadUrgentManagementList('rts', params)}`,
      'Urgent_RTS_LIST',
      '.xlsx',
      true
    )
  }

  async getHALList(
    params: UrgentDeliveryManagementHALListRequest
  ): Promise<UrgentDeliveryManagementHALList> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getUrgentDeliveryManagementHALList(params)
    );
    return this.mapper.convertHALListFromApi(resp as AxiosResponse);
  }

  async getUrgentDeliveryManagementTakenRecipient(
    params: UrgentDeliveryManagementHALListRequest
  ): Promise<UrgentDeliveryManagementTakenByRecipientList> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getUrgentDeliveryManagementTakenByRecipientList(params)
    );
    return this.mapper.convertListTakenByRecipient(resp);
  }

  async downloadUrgentHALList(
    params: UrgentDeliveryManagementHALListRequestDownload
  ): Promise<void> {
    return downloadFile(
      `${Endpoints.baseUrl}${this.endpoints.downloadUrgentManagementList('hal', params)}`,
      'Urgent_HAL_LIST',
      '.xlsx',
      true
    )
  }

  async getPriorityDeliveryManagementDetail(
    id: number
  ): Promise<UrgentDeliveryManagementDetailData> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getPriorityDeliveryManagementDetail(id)
    );
    return this.mapper.convertDeliveryManagementDetailDataFromApi(resp);
  }
}
