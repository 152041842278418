import { ChangeStiScRequestInterface } from "@/data/payload/contracts/StiScRequest";
import { StiSCRepositoryInterface } from "@/data/persistences/repositories/contracts/StiSCRepositoryInterface";
import {
  RequestListSTISCSession,
  ResponseSTISCCreateSession,
  ResponseSTISCSession,
  RequestListSTISC,
  StiSCEntities,
  StiScManifestData,
  StiScProcessData,
  SttPiecesStiScDetail,
  ValidationScanSTTData,
  PayloadProcessUpdateSTISC,
  ResponseFinishSessionSTISC,
  STISCReverseScanTemporary,
  STISCReverseScanCreate,
  STIScListBookedEntities,
  STIScSTTDueEntities,
  STISCSTTDueSummary
} from "@/domain/entities/StiSc";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import {
  GenerateApiRequest,
  UpdateToSTISCRequest,
  FinishSessionSTISCRequest,
  STISCReverseScanTemporaryListRequest,
  CreateSTISCReverseScanRequest,
  STISCSTTDueSummaryRequest,
  STISCReverseScanManifestRequest,
  STIScSTTDueListRequest
} from "@/data/payload/api/StiScApiRequest";
import downloadFile from "../../misc/common-library/DownloadFile";
import { StiSCMapper } from "@/data/persistences/mappers/StiSCMapper";

export class StiSCApiRepository implements StiSCRepositoryInterface {
  private service: ApiService;
  private mapper: StiSCMapper;
  private endpoints: Endpoints;

  constructor(service: ApiService, mapper: StiSCMapper, endpoints: Endpoints) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getListStiSC(params: RequestListSTISC): Promise<StiSCEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getStiSCList(params),
      {}
    );
    return this.mapper.convertStiSCDataFromApi(resp);
  }

  public async getDetailSttPieces(
    sttNumber: string
  ): Promise<SttPiecesStiScDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailSttPiecesStiSc(sttNumber),
      {}
    );
    return this.mapper.convertSttPiecesStiScDetailFromApi(resp);
  }

  public async generateStiSC(
    payload: ChangeStiScRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.generateStiSc(),
      undefined,
      payload as GenerateApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async validationScanSTTStiSc(
    sttPiece: string
  ): Promise<ValidationScanSTTData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.validationScanSTTStiSc(sttPiece),
      {}
    );
    return this.mapper.convertValidationScanSTTApi(resp);
  }

  public async getStiSCManifestDetail(id: number): Promise<StiScManifestData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getStiSCManifestDetail(id),
      {}
    );
    return this.mapper.convertStiScManifestDataFromApi(resp);
  }

  public async getStiSCSession(
    param: RequestListSTISCSession
  ): Promise<ResponseSTISCSession> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSTISCSession(param),
      {}
    );
    return this.mapper.convertSessionSTISCFromApi(resp);
  }

  public async processUpdateStiSC(
    payload: ChangeStiScRequestInterface
  ): Promise<StiScProcessData> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.processUpdateSTISC(),
      undefined,
      payload as UpdateToSTISCRequest
    );
    return this.mapper.convertStiScProcessDataFromApi(resp);
  }

  public async createStiScSession(): Promise<ResponseSTISCCreateSession> {
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.createSTISCSession(),
      {}
    );
    return this.mapper.convertCreateSessionSTISCFromApi(resp);
  }

  public async finishStiSCSession(
    payload: ChangeStiScRequestInterface
  ): Promise<ResponseFinishSessionSTISC> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.finishSessionSTISC(),
      undefined,
      payload as FinishSessionSTISCRequest
    );
    return this.mapper.convertStiScFinishDataFromApi(resp);
  }

  async getBookedList(): Promise<STIScListBookedEntities> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getSTIScBookedList()
    );

    return this.mapper.convertListBookedFromApi(resp);
  }

  async getSTTDueList(
    params: STIScSTTDueListRequest
  ): Promise<STIScSTTDueEntities> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getSTIScSTTDueList(params)
    );

    return this.mapper.convertSTTDueListFromApi(resp);
  }

  public async downloadSttDue(params: STIScSTTDueListRequest): Promise<any> {
    return downloadFile(
      `${Endpoints.baseUrl}${this.endpoints.downloadSTIScSTTDue(params)}`,
      `STT belum di STI SC`,
      ".xlsx",
      true
    );
  }
  public async getSTISCSTTDueSummary(): Promise<STISCSTTDueSummary> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getSTISCSTTDueSummary()
    );

    return this.mapper.convertStiSCSTTDueSummaryFromApi(resp);
  }

  public async downloadSTISCExcel(id: number): Promise<any> {
    return downloadFile(
      `${Endpoints.baseUrl}${this.endpoints.downloadManifest(
        `sti/sc/excel-stt-due/${id}`, "v2"
      )}`,
      `STI_SC_Manifest_${id}`,
      ".xlsx",
      true
    );
  }
  async getStiScReverseScanTemporary(
    payload: STISCReverseScanTemporaryListRequest
  ): Promise<STISCReverseScanTemporary[]> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getSTISCReverseScanTemporary(payload),
      undefined
    );

    return this.mapper.convertStiScReverseScanTemporaryFromApi(resp);
  }

  async createStiScReverseScan(
    payload: CreateSTISCReverseScanRequest
  ): Promise<STISCReverseScanCreate> {
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.createSTISCReverseScan(),
      undefined,
      payload
    );

    return this.mapper.convertStiScReverseScanCreateFromApi(resp);
  }

  async getStiScSttDueSummary(
    payload: STISCSTTDueSummaryRequest
  ): Promise<STISCSTTDueSummary> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getStiScSttDueSummary(payload)
    );

    return this.mapper.convertStiSCSTTDueSummaryFromApi(resp);
  }

  async stiScReverseScanManifest(
    payload: STISCReverseScanManifestRequest
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.stiScReverseScanManifest(),
      undefined,
      payload
    );

    return this.mapper.convertChangeDataFromApi(resp);
  }
}
