import {
  UrgentDeliveryData,
  UrgentDeliveryDetailData,
  UrgentDeliveryListEntities,
  UrgentDeliverySTTStatusData,
  UrgentDeliverySTTStatusListEntities,
} from "@/domain/entities/UrgentDelivery";
import { AxiosResponse } from "axios";
import { PaginationV2 } from "@/domain/entities/Pagination";

export class UrgentDeliveryMapper {
  public convertListDataFromApi(
    res: AxiosResponse<any>
  ): UrgentDeliveryListEntities {
    const { data } = res;

    if (!data.data.length) {
      return new UrgentDeliveryListEntities({
        pagination: new PaginationV2({
          page: data.meta.page,
          limit: data.meta.limit,
          totalData: data.meta.total_records,
        }),
      });
    }
    return new UrgentDeliveryListEntities({
      pagination: new PaginationV2({
        page: data.meta.page,
        limit: data.meta.limit,
        totalData: data.meta.total_records,
      }),
      data: data.data.map(
        (item: any) => {
          const limitDate = item.rpd_deadline_return?.split("-")
          return new UrgentDeliveryData({
            rpdId: item.rpd_id,
            rpdPriorityDeliveryId: item.rpd_priority_delivery_id,
            rpdSttNo: item.rpd_stt_no,
            rpdRequestType: item.rpd_request_type,
            rpdReason: item.rpd_reason,
            rpdDeadlineReturn: limitDate[0] === "0001" ? "-" : item.rpd_deadline_return,
            sttLastStatusId: item.stt_last_status_id,
            sttDestinationCity: item.stt_destination_city,
            rpdCreatedAt: item.rpd_created_at,
            rpdUpdatedAt: item.rpd_updated_at,
            rpdCreatedBy: item.rpd_created_by_name
          })
        }),
    });
  }

  public convertSTTStatusListDataFromApi(
    res: AxiosResponse<any>
  ): UrgentDeliverySTTStatusListEntities {
    const { data } = res;

    return new UrgentDeliverySTTStatusListEntities({
      data: data.data.map(
        (status: Record<string, string>) =>
          new UrgentDeliverySTTStatusData({
            name: status.name,
          })
      ),
    });
  }

  public convertDetailDataFromApi(
    res: AxiosResponse<any>
  ): UrgentDeliveryDetailData {
    const { data } = res.data;
    const limitDate = data.rpd_deadline_return?.split("-")
    return new UrgentDeliveryDetailData({
      rpdId: data.rpd_id,
      rpdPriorityDeliveryId: data.rpd_priority_delivery_id,
      rpdSttNo: data.rpd_stt_no,
      rpdRequestType: data.rpd_request_type,
      rpdReason: data.rpd_reason,
      rpdDeadlineReturn: limitDate[0] === "0001" ? "-" : data.rpd_deadline_return,
      sttLastStatusId: data.stt_last_status_id,
      sttDestinationCity: data.stt_destination_city,
      rpdNotes: data.rpd_notes,
      rpdProofPhoto: data.rpd_proof_photo,
      rpdCreatedAt: data.rpd_created_at,
      rpdUpdatedAt: data.rpd_updated_at,
      rpdCreatedBy: data.rpd_created_by_name,
      rpdLink: data.rpd_link
    });
  }
}
