import { Pagination } from "@/domain/entities/Pagination";

export class PartnerDetail {
  partnerId = 0;
  partnerType = "";
  partnerName = "";
  partnerCode = "";
  partnerElexysCode = "";
  partnerBalance = 0;
  partnerAddress = "";
  partnerLocationId = 0;
  partnerLocationCityCode = "";
  partnerDistrict = "";
  partnerDistrictCode = "";
  partnerCity: Array<any> = [];
  partnerPhoneNumber = "";
  partnerTaxNumber = "";
  partnerParentType = "";
  partnerParentId = 0;
  partnerParentName = "";
  partnerStartContract = new Date();
  partnerEndContract = new Date();
  partnerContactName = "";
  partnerContactJobTitle = "";
  partnerContactEmail = "";
  partnerContactPhoneNumber: any;
  partnerContractStatus = "";
  partnerIsBanned = false;
  partnerBannedReason = "";
  partnerCreatedAt = new Date();
  partnerUpdatedAt = new Date();
  partnerCreatedBy = "";
  partnerUpdatedBy = "";
  vaNumbers: VaNumbers[] = [];
  partnerLatitude = "";
  partnerLongitude = "";
  partnerReferralCode = "";
  partnerReferrerCode = "";
  partnerIsCodDelivery = false;
  partnerIsCodBooking = false;
  partnerIsPcu = false;
  partnerIsAllowBookVippack = false;
  partnerIdSttReturn = 0;
  partnerNameSttReturn = "";
  partnerTypeSttReturn = "";
  partnerPosType = "";
  partnerPosParentId = undefined as number | undefined;
  partnerPosParentName = "";
  partnerPosParentIdDefault = undefined as number | undefined;
  partnerPosParentNameDefault = "";
  partnerPosReverseJourney = "";
  partnerPosBranchCommission: any = 0;
  partnerPosReverseJourneyCode = "";
  partnerPosReverseJourneyName = "";
  partnerPosAttachFiles: string[] = [];
  partnerPosSaldoDeposit = 0;
  partnerPosSaldoDepositFormated = "";
  partnerPosFirstDateTopup = "";
  partnerPosFirstTransaction = "";
  partnerPosPickupManifest = "";
  partnerPosTieringLevel = "";

  constructor(fields?: Partial<PartnerDetail>) {
    Object.assign(this, fields);
  }
}

export class ConsolidatorByCity {
  pagination: Pagination;
  consolidatorByCityData: Array<ConsolidatorByCityData>;
  constructor(
    pagination: Pagination,
    consolidatorByCityData: Array<ConsolidatorByCityData>
  ) {
    this.pagination = pagination;
    this.consolidatorByCityData = consolidatorByCityData;
  }
}

export class VaNumbers {
  bankAccountAccountNumber: string;
  bankAccountBankLogo: string;
  bankAccountBankName: string;
  bankAccountLabel: string;
  constructor(
    bankAccountAccountNumber: string,
    bankAccountBankLogo: string,
    bankAccountBankName: string,
    bankAccountLabel: string
  ) {
    this.bankAccountAccountNumber = bankAccountAccountNumber;
    this.bankAccountBankLogo = bankAccountBankLogo;
    this.bankAccountBankName = bankAccountBankName;
    this.bankAccountLabel = bankAccountLabel;
  }
}

export class ConsolidatorByCityData {
  id: number;
  name: string;
  address: string;
  balance: number;
  code: string;
  lat: number;
  long: number;
  type: string;
  createdAt: string;
  updatedAt: string;
  parentId: number;
  parentName: string;
  parentType: string;
  contractEnd: string;
  contractStatus: string;
  isBanned: boolean;
  isRegistry: boolean;

  constructor(
    id: number,
    name: string,
    address: string,
    balance: number,
    code: string,
    lat: number,
    long: number,
    type: string,
    createdAt: string,
    updatedAt: string,
    parentId: number,
    parentName: string,
    parentType: string,
    contractEnd: string,
    contractStatus: string,
    isBanned: boolean,
    isRegistry: boolean
  ) {
    this.id = id;
    this.name = name;
    this.address = address;
    this.balance = balance;
    this.code = code;
    this.lat = lat;
    this.long = long;
    this.type = type;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.parentId = parentId;
    this.parentName = parentName;
    this.parentType = parentType;
    this.contractEnd = contractEnd;
    this.contractStatus = contractStatus;
    this.isBanned = isBanned;
    this.isRegistry = isRegistry;
  }
}

export class PartnerBank {
  bankCode = "";
  bankName = "";
  bankRouting: string[] = [];
  constructor(fields?: Partial<PartnerBank>) {
    Object.assign(this, fields);
  }
}

export class ClientNamePOS {
  pagination: Pagination;
  data: Array<ClientNamePOSData>;

  constructor(pagination: Pagination, data: Array<ClientNamePOSData>) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class ClientNamePOSData {
  clientId: number;
  clientCode: string;
  clientName: string;
  clientStatus: boolean;
  clientIsCOD: boolean;
  clientIsDO: boolean;
  clientOriginCity: string;
  clientCategory: string;
  clientPackageType: string;
  clientCodConfigAmount: string;

  constructor(
    clientId: number,
    clientCode: string,
    clientName: string,
    clientStatus: boolean,
    clientIsCOD: boolean,
    clientIsDO: boolean,
    clientOriginCity: string,
    clientCategory: string,
    clientPackageType: string,
    clientCodConfigAmount: string
  ) {
    this.clientId = clientId;
    this.clientCode = clientCode;
    this.clientName = clientName;
    this.clientStatus = clientStatus;
    this.clientIsCOD = clientIsCOD;
    this.clientIsDO = clientIsDO;
    this.clientOriginCity = clientOriginCity;
    this.clientCategory = clientCategory;
    this.clientPackageType = clientPackageType;
    this.clientCodConfigAmount = clientCodConfigAmount
  }
}
