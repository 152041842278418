import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import { Pagination } from "@/domain/entities/Pagination";
import {
  STIEntities,
  STIData,
  DetailSttPiecesSTI,
  DetailPieces,
  StiManifestData,
  StiManifestStt,
  StiManifestPiece,
  DetailStt,
  STIReverseScanTemporary,
  STIReverseScanGenerate,
  STIReverseScanGenerateData,
  STIReverseScanDetail,
  STISTTDueSummary,
  STIListBookedEntities,
  STIListBooked,
  STISTTDueEntities,
  NeedToSTISTT
} from "@/domain/entities/STI";
import { LocationController } from "@/app/ui/controllers/LocationController";

export class STIMapper {
  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: data.data.sti_id || 0
    });
  }

  public convertSTIDataFromApi(res: AxiosResponse<any>): STIEntities {
    const { data } = res;

    const STI: STIData[] = [];
    if (data.data.length === 0) {
      return new STIEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        STI.push(
          new STIData(
            item.sti_id,
            item.sti_partner_id,
            item.sti_partner_code,
            item.sti_partner_name,
            item.sti_total_stt,
            item.sti_total_gross_weight,
            item.sti_total_volume_weight,
            item.sti_total_pieces,
            item.sti_created_at,
            item.sti_created_by,
            item.sti_created_name,
            item.sti_updated_at,
            item.sti_total_sti_dest || 0,
            item.sti_total_status_return_pod || 0,
            item.sti_total_status_return_rts || 0,
            item.sti_total_status_return_hal || 0,
            item.has_stt_need_sti || false
          )
        );
      });
    }
    return new STIEntities(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      STI
    );
  }

  public convertSttPiecesStiDetailFromApi(
    res: AxiosResponse<any>
  ): DetailSttPiecesSTI {
    const {
      data: { data }
    } = res;

    const cityCodeLocation = LocationController.districtDetail.cityCode;

    return new DetailSttPiecesSTI({
      totalStt: data.total_stt,
      totalPieces: data.total_koli,
      totalSuccess: data.total_stt_success,
      totalFailed: data.total_stt_failed,
      stiResult: data?.sti_result,
      stts: data?.stts?.length
        ? data.stts.map(
            (item: any) =>
              new DetailStt({
                isAllowUpdateStatus: item.is_allow_update_status,
                isPaid: item.is_paid,
                errorMessage: item.error_message,
                sttId: item?.stt?.stt_id,
                sttNo: item?.stt?.stt_no,
                sttProductType: item?.stt?.stt_product_type,
                sttTotalPiece: item?.stt?.stt_total_piece,
                sttDestinationDistrictUrsacode:
                  item?.stt?.stt_destination_district_ursacode,
                packageNumber: item?.stt?.package_number,
                sttDestinationRegionName:
                  item?.stt?.stt_destination_region_name,
                sttOriginRegionName: item?.stt?.stt_origin_region_name,
                sttDestinationCityId: item?.stt?.stt_destination_city_id,
                sttDestinationCityName: item?.stt?.stt_destination_city_name,
                sttOriginCityId: item?.stt?.stt_origin_city_id,
                sttOriginCityName: item?.stt?.stt_origin_city_name,
                sttWoodPacking:
                  item?.stt?.stt_wood_packing?.toLowerCase() === "yes",
                sttCommodityCode: item?.stt?.stt_commodity_code,
                sttCommodityName: item?.stt?.stt_commodity_name,
                sttGrossWeight: item?.stt?.stt_gross_weight,
                sttVolumeWeight: item?.stt?.stt_volume_weight,
                sttChargeableWeight: item?.stt?.stt_chargeable_weight,
                sttLastStatusId: item?.stt?.stt_last_status_id,
                sttPaymentStatus: item?.stt?.stt_payment_status,
                isSti: true,
                isStiDest:
                  cityCodeLocation === item?.stt?.stt_destination_city_id,
                piece: item?.stt?.piece?.length
                  ? item?.stt?.piece?.map(
                      (piece: any) =>
                        new DetailPieces({
                          sttPieceId: piece?.stt_piece_id,
                          sttPieceGrossWeight: piece?.stt_piece_gross_weight,
                          sttPieceVolumeWeight: piece?.stt_piece_volume_weight,
                          sttPieceNo: piece?.stt_piece_no,
                          sttPieceLastStatusId: piece?.stt_piece_last_status_id
                        })
                    )
                  : []
              })
          )
        : []
    });
  }

  public convertStiManifestDataFromApi(
    res: AxiosResponse<any>
  ): StiManifestData {
    const {
      data: { data }
    } = res;
    if (data === null) {
      return new StiManifestData();
    } else {
      return new StiManifestData({
        stiId: data.sti_id,
        consolidatorName: data.consolidator_name,
        originConsolidator: data.origin_consolidator,
        stiDate: data.sti_date,
        totalStt: data.total_stt,
        totalPiece: data.total_piece,
        totalGrossWeight: data.total_gross_weight,
        totalVolumeWeight: data.total_volume_weight,
        createdName: data.created_name,
        needToStiTotalStt: data.need_to_sti_total_stt,
        stt: data.stt.map(
          (item: any) =>
            new StiManifestStt({
              sttId: item.stt_id,
              sttNo: item.stt_no,
              sttProductType: item.stt_product_type,
              sttTotalPiece: item.stt_total_piece,
              stiOriginCity: item.stt_origin_city_id,
              stiDestCity: item.stt_destination_city_id,
              stiOriginRegion: item.stt_origin_region_name,
              stiDestRegion: item.stt_destination_region_name,
              stiGrossWeight: item.stt_gross_weight,
              stiVolumeWeight: item.stt_volume_weight,
              bagNo: item.bag_no || "",
              statusReturn: item.status_return || "",
              isStiDest: item.is_sti_dest || 0,
              refNo: item.ref_no || item.stt_no_ref_external || item.stt_shipment_id || item.booking_id || "",
              piece: item.piece.map(
                (piece: any) =>
                  new StiManifestPiece({
                    sttPieceId: piece.stt_piece_id,
                    sttPieceNo: piece.stt_piece_no,
                    sttPieceGrossWeight: piece.stt_piece_gross_weight,
                    sttPieceVolumeWeight: piece.stt_piece_volume_weight
                  })
              ),
              isDangerousGoods: item.is_dangerous_goods
            })
        ),
        totalSTIDest: data.total_sti_dest || 0,
        statusReturnPOD: data.total_status_return_pod || 0,
        statusReturnRTS: data.total_status_return_rts || 0,
        statusReturnHAL: data.total_status_return_hal || 0,
      });
    }
  }

  public convertResponsePayload(res: AxiosResponse<any>): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      sttId: data.sti_id,
      data: data.data
    });
  }

  public convertStiReverseScanGenerateFromApi(
    res: AxiosResponse
  ): STIReverseScanGenerate {
    const { data } = res;

    const sttData: any = data.data.stt;
    const bagData: any[] = data.data.bag || [];

    return new STIReverseScanGenerate({
      message: data.message,
      success: data.success,
      stiId: data.data.sti_id,
      stt: sttData?.stt_no
        ? new STIReverseScanGenerateData({
            bagNo: sttData.bag_no,
            bookingId: sttData.booking_id,
            flag: sttData.flag,
            isStiDest: sttData.is_sti_dest,
            packageNumber: sttData.package_number,
            piece: sttData.piece.map(
              (item: any) =>
                new StiManifestPiece({
                  sttPieceId: item.stt_piece_id,
                  sttPieceGrossWeight: item.stt_piece_gross_weight,
                  sttPieceLastStatusId: item.stt_piece_last_status_id,
                  sttPieceNo: item.stt_piece_no,
                  sttPieceVolumeWeight: item.stt_piece_volume_weight
                })
            ),
            refNo: sttData.ref_no,
            statusReturn: sttData.status_return,
            deadlineReturn: sttData.deadline_return,
            stiDestOneBagScan: sttData.sti_dest_one_bag_scan,
            sttChargeableWeight: sttData.stt_chargeable_weight,
            sttCommodityCode: sttData.stt_commodity_code,
            sttCommodityName: sttData.stt_commodity_name,
            sttDestinationCityId: sttData.stt_destination_city_id,
            sttDestinationCityName: sttData.stt_destination_city_name,
            sttDestinationDistrictUrsacode:
              sttData.stt_destination_district_ursacode,
            sttDestinationRegionName: sttData.stt_destination_region_name,
            sttGrossWeight: sttData.stt_gross_weight || 0,
            sttId: sttData.stt_id,
            sttLastStatusId: sttData.stt_last_status_id,
            sttNo: sttData.stt_no,
            sttNoRefExternal: sttData.stt_no_ref_external,
            sttOriginCityId: sttData.stt_origin_city_id,
            sttOriginCityName: sttData.stt_origin_city_name,
            sttOriginRegionName: sttData.stt_origin_region_name,
            sttPaymentStatus: sttData.stt_payment_status,
            sttProductType: sttData.stt_product_type,
            sttRegionId: sttData.stt_region_id,
            sttRegionName: sttData.stt_region_name,
            sttShipmentId: sttData.stt_shipment_id,
            sttTotalPiece: sttData.stt_total_piece,
            sttVolumeWeight: sttData.stt_volume_weight,
            sttWoodPacking: sttData.stt_wood_packing,
            isDangerousGoods: sttData.is_dangerous_goods,
            bookedType: sttData.booked_type,
            bookedName: sttData.booked_name,
            bookedId: sttData.booked_id,
            isPaid: sttData.is_paid,
            sttAssessmentStatus: sttData.stt_assessment_status
          })
        : new STIReverseScanGenerateData(),
      bag: bagData.map(
        (item: any) =>
          new STIReverseScanGenerateData({
            bagNo: item.bag_no,
            bookingId: item.booking_id,
            flag: item.flag,
            isStiDest: item.is_sti_dest,
            packageNumber: item.package_number,
            piece: item.piece.map(
              (item: any) =>
                new StiManifestPiece({
                  sttPieceId: item.stt_piece_id,
                  sttPieceGrossWeight: item.stt_piece_gross_weight,
                  sttPieceLastStatusId: item.stt_piece_last_status_id,
                  sttPieceNo: item.stt_piece_no,
                  sttPieceVolumeWeight: item.stt_piece_volume_weight
                })
            ),
            refNo: item.ref_no,
            statusReturn: item.status_return,
            deadlineReturn: item.deadline_return,
            stiDestOneBagScan: item.sti_dest_one_bag_scan,
            sttChargeableWeight: item.stt_chargeable_weight,
            sttCommodityCode: item.stt_commodity_code,
            sttCommodityName: item.stt_commodity_name,
            sttDestinationCityId: item.stt_destination_city_id,
            sttDestinationCityName: item.stt_destination_city_name,
            sttDestinationDistrictUrsacode:
              item.stt_destination_district_ursacode,
            sttDestinationRegionName: item.stt_destination_region_name,
            sttGrossWeight: item.stt_gross_weight || 0,
            sttId: item.stt_id,
            sttLastStatusId: item.stt_last_status_id,
            sttNo: item.stt_no,
            sttNoRefExternal: item.stt_no_ref_external,
            sttOriginCityId: item.stt_origin_city_id,
            sttOriginCityName: item.stt_origin_city_name,
            sttOriginRegionName: item.stt_origin_region_name,
            sttPaymentStatus: item.stt_payment_status,
            sttProductType: item.stt_product_type,
            sttRegionId: item.stt_region_id,
            sttRegionName: item.stt_region_name,
            sttShipmentId: item.stt_shipment_id,
            sttTotalPiece: item.stt_total_piece,
            sttVolumeWeight: item.stt_volume_weight,
            sttWoodPacking: item.stt_wood_packing,
            isDangerousGoods: item.is_dangerous_goods,
            bookedId: item.booked_id,
            bookedName: item.booked_name,
            bookedType: item.booked_type,
            isPaid: item.is_paid,
            sttAssessmentStatus: item.stt_assessment_status
          })
      )
    });
  }

  public convertStiReverseScanTemporaryListFromApi(
    res: AxiosResponse
  ): STIReverseScanTemporary[] {
    const { data } = res;

    return data.data.map(
      (res: any) =>
        new STIReverseScanTemporary({
          stId: res.st_id,
          stAccountId: res.st_account_id,
          stIsActive: res.st_is_active,
          stSttNo: res.st_stt_no,
          stProduct: res.st_product,
          stOrigin: res.st_origin,
          stDestination: res.st_destination,
          stRegionId: res.st_region_id,
          stDeadlineReturn: res.st_deadline_return,
          stMeta: {
            bagNo: res.st_meta.bag_no,
            flag: res.st_meta.flag,
            isStiDest: res.st_meta.is_sti_dest,
            pieces: res.st_meta.pieces,
            refNo: res.st_meta.ref_no,
            statusReturn: res.st_meta.status_return,
            isDangerousGoods: res.st_meta.is_dangerous_goods,
            isPaid: res.st_meta.is_paid || false,
            grossWeight: res.st_meta.gross_weight || 0,
            sttAssessmentStatus: res.st_meta.stt_assessment_status
          },
          stCreatedAt: res.st_created_at,
          stUpdatedAt: res.st_updated_at,
          stBookedId: res.st_booked_id,
          stBookedName: res.st_booked_name,
          stBookedType: res.st_booked_type
        })
    );
  }

  public convertStiReverseScanDetailFromApi(
    res: AxiosResponse
  ): STIReverseScanDetail {
    const { data } = res;

    const detailData = data.data;
    const needToStiStt = detailData.need_to_sti_stt || [];

    return new STIReverseScanDetail({
      consolidatorName: detailData.consolidator_name,
      createdName: detailData.created_name,
      destinationCityId: detailData.destination_city_id,
      destinationCityName: detailData.destination_city_name,
      originConsolidator: detailData.origin_consolidator,
      stiDate: detailData.sti_date,
      stiId: detailData.sti_id,
      totalGrossWeight: detailData.total_gross_weight,
      totalPiece: detailData.total_piece,
      totalStatusReturnHal: detailData.total_status_return_hal,
      totalStatusReturnPod: detailData.total_status_return_pod,
      totalStatusReturnRts: detailData.total_status_return_rts,
      totalStiDest: detailData.total_sti_dest,
      totalStt: detailData.total_stt,
      totalVolumeWeight: detailData.total_volume_weight,
      stt: detailData.stt.map((item: any) => {
        return new STIReverseScanGenerateData({
          bagNo: item.bag_no,
          bookingId: item.booking_id,
          flag: item.flag,
          isStiDest: item.is_sti_dest,
          packageNumber: item.package_number,
          piece: item.piece.map(
            (item: any) =>
              new StiManifestPiece({
                sttPieceId: item.stt_piece_id,
                sttPieceGrossWeight: item.stt_piece_gross_weight,
                sttPieceLastStatusId: item.stt_piece_last_status_id,
                sttPieceNo: item.stt_piece_no,
                sttPieceVolumeWeight: item.stt_piece_volume_weight
              })
          ),
          refNo: item.ref_no,
          statusReturn: item.status_return,
          deadlineReturn: item.deadline_return,
          stiDestOneBagScan: item.sti_dest_one_bag_scan,
          sttChargeableWeight: item.stt_chargeable_weight,
          sttCommodityCode: item.stt_commodity_code,
          sttCommodityName: item.stt_commodity_name,
          sttDestinationCityId: item.stt_destination_city_id,
          sttDestinationCityName: item.stt_destination_city_name,
          sttDestinationDistrictUrsacode:
            item.stt_destination_district_ursacode,
          sttDestinationRegionName: item.stt_destination_region_name,
          sttGrossWeight: item.stt_gross_weight,
          sttId: item.stt_id,
          sttLastStatusId: item.stt_last_status_id,
          sttNo: item.stt_no,
          sttNoRefExternal: item.stt_no_ref_external,
          sttOriginCityId: item.stt_origin_city_id,
          sttOriginCityName: item.stt_origin_city_name,
          sttOriginRegionName: item.stt_origin_region_name,
          sttPaymentStatus: item.stt_payment_status,
          sttProductType: item.stt_product_type,
          sttRegionId: item.stt_region_id,
          sttRegionName: item.stt_region_name,
          sttShipmentId: item.stt_shipment_id,
          sttTotalPiece: item.stt_total_piece,
          sttVolumeWeight: item.stt_volume_weight,
          sttWoodPacking: item.stt_wood_packing,
          isDangerousGoods: item.is_dangerous_goods
        });
      }),
      needToStiTotalStt: detailData.need_to_sti_total_stt,
      needToStiTotalPiece: detailData.need_to_sti_total_piece,
      needToStiCycleDate: detailData.need_to_sti_cycle_date,
      needToStiTotalGrossWeight: detailData.need_to_sti_total_gross_weight,
      needToStiStt: needToStiStt.map((item: any) => {
        return new STIReverseScanGenerateData({
          bagNo: item.bag_no,
          sttNo: item.stt_no,
          refNo: item.ref_no,
          sttProductType: item.stt_product_type,
          sttTotalPiece: item.stt_total_piece,
          sttDestinationCityId: item.stt_destination_city_id,
          bookedName: item.booked_name
        });
      })
    });
  }

  public convertStiSTTDueSummaryFromApi(res: AxiosResponse): STISTTDueSummary {
    const { data } = res;

    const summaryData = data.data;

    return new STISTTDueSummary({
      total: summaryData.total,
      totalOverdue: summaryData.total_overdue,
      totalNow: summaryData.total_now,
      totalBooked:summaryData.total_booked
    });
  }

  public convertListBookedFromApi(res: AxiosResponse): STIListBookedEntities {
    const { data } = res;

    const bookedList = data.data || [];

    return new STIListBookedEntities({
      pagination: new Pagination(
        data.meta.page,
        data.meta.limit,
        data.meta.total_records
      ),
      data: bookedList.map((booked: any) => {
        return new STIListBooked({
          bookedId: booked.booked_id,
          bookedName: booked.booked_name,
          bookedType: booked.booked_type
        });
      })
    });
  }

  public convertSTTDueListFromApi(res: AxiosResponse): STISTTDueEntities {
    const { data } = res;

    const sttList = data.data || [];

    return new STISTTDueEntities({
      pagination: new Pagination(
        data.meta.page,
        data.meta.limit,
        data.meta.total_records
      ),
      data: sttList.map((stt: any) => {
        return new NeedToSTISTT({
          sdId: stt.sd_id,
          bagNo: stt.bag_no,
          sttNo: stt.stt_no,
          refNo: stt.ref_no,
          sttLastStatusId: stt.stt_last_status_id,
          sttProductType: stt.stt_product_type,
          sttTotalPiece: stt.stt_total_piece,
          sttGrossWeight: stt.stt_gross_weight,
          sttDestinationCityId: stt.stt_destination_city_id,
          sttBookedAt: stt.stt_booked_at,
          sttCreatedName: stt.stt_created_name,
          sttBookedName: stt.stt_booked_name,
          deadline: stt.deadline
        });
      })
    });
  }
}
