/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionOutgoingShipmentSTISC = {
  permission_sti_sc_enable: new Rox.Flag(false),
  permission_sti_sc_create: new Rox.Flag(false),
  reverse_scan_sti_sc_enable: new Rox.Flag(true)
};

export function initFlagsPermissionOutgoingShipmentSTISC() {
  // register flags with namespaces for better grouping
  Rox.register(
    "featureOutgoingShipmentSTISC",
    FlagsPermissionOutgoingShipmentSTISC
  );
}
