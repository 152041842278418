import { StiSCApiRepository } from "@/app/infrastructures/repositories/api/StiSCApiRepository";
import { injectable } from "tsyringe";
import {
  StiSCEntities,
  SttPiecesStiScDetail,
  ValidationScanSTTData,
  StiScManifestData,
  RequestListSTISCSession,
  ResponseSTISCSession,
  StiScProcessData,
  ResponseSTISCCreateSession,
  RequestListSTISC,
  ResponseFinishSessionSTISC,
  STISCReverseScanTemporary,
  STISCReverseScanCreate,
  STIScListBookedEntities,
  STIScSTTDueEntities,
  STISCSTTDueSummary
} from "@/domain/entities/StiSc";
import { ChangeStiScRequestInterface } from "@/data/payload/contracts/StiScRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  CreateSTISCReverseScanRequest,
  STISCReverseScanManifestRequest,
  STISCReverseScanTemporaryListRequest,
  STIScSTTDueListRequest,
  STISCSTTDueSummaryRequest
} from "@/data/payload/api/StiScApiRequest";

@injectable()
export class StiSCPresenter {
  private repository: StiSCApiRepository;

  constructor(repository: StiSCApiRepository) {
    this.repository = repository;
  }

  public getListStiSC(params : RequestListSTISC): Promise<StiSCEntities> {
    return this.repository.getListStiSC(params);
  }

  public getDetailSttPieces(sttNumber: string): Promise<SttPiecesStiScDetail> {
    return this.repository.getDetailSttPieces(sttNumber);
  }

  public async generateStiSc(
    payload: ChangeStiScRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.generateStiSC(payload);
  }

  public async validationScanSTTStiSc(
    sttPiece: string
  ): Promise<ValidationScanSTTData> {
    return this.repository.validationScanSTTStiSc(sttPiece);
  }

  public async getStiSCManifestDetail(id: number): Promise<StiScManifestData> {
    return this.repository.getStiSCManifestDetail(id);
  }

  public async getStiSCSession(param: RequestListSTISCSession): Promise<ResponseSTISCSession> {
    return this.repository.getStiSCSession(param);
  }

  public async processUpdateSTISC(payload: ChangeStiScRequestInterface): Promise<StiScProcessData> {
    return this.repository.processUpdateStiSC(payload);
  }
  public async createStiSCSession(): Promise<ResponseSTISCCreateSession> {
    return this.repository.createStiScSession();
  }
  public async finishStiSCSession(payload: ChangeStiScRequestInterface): Promise<ResponseFinishSessionSTISC> {
    return this.repository.finishStiSCSession(payload);
  }
  public async getBookedList(): Promise<STIScListBookedEntities> {
    return this.repository.getBookedList();
  }
  public async getSTTDueList(
    params: STIScSTTDueListRequest
  ): Promise<STIScSTTDueEntities> {
    return this.repository.getSTTDueList(params);
  }

  public async downloadSttDue(params: STIScSTTDueListRequest): Promise<any> {
    return this.repository.downloadSttDue(params);
  }

  public async getSTISCSTTDueSummary(): Promise<STISCSTTDueSummary> {
    return this.repository.getSTISCSTTDueSummary();
  }

  public async downloadSTISCExcel(id: number): Promise<any> {
    return this.repository.downloadSTISCExcel(id);
  }
  public async getStiScReverseScanTemporary(
    params: STISCReverseScanTemporaryListRequest
  ): Promise<STISCReverseScanTemporary[]> {
    return this.repository.getStiScReverseScanTemporary(params);
  }
  public async createStiScReverseScan(
    payload: CreateSTISCReverseScanRequest
  ): Promise<STISCReverseScanCreate> {
    return this.repository.createStiScReverseScan(payload);
  }
  public async getStiScSttDueSummary(
    payload: STISCSTTDueSummaryRequest
  ): Promise<STISCSTTDueSummary> {
    return this.repository.getStiScSttDueSummary(payload);
  }
  public async stiScReverseScanManifest(
    payload: STISCReverseScanManifestRequest
  ): Promise<ResponsePayload> {
    return this.repository.stiScReverseScanManifest(payload);
  }
}
